import { Component } from '@angular/core';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrl: '../supervisor-main.component.css'
})
export class LibraryComponent {

}
