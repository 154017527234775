import { Component, effect, input } from '@angular/core';
import { SkillAreaModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { DialogHelperService } from '../dialog.helper.service';
import { Utils } from '../../core/utils';

@Component({
  selector: 'app-skill-areas',
  templateUrl: './skill-areas.component.html',
  styleUrl: './skill-areas.component.css',
})
export class SkillAreasComponent {
  skillAreas: SkillAreaModel[] = [];

  loadingSkillAreas: boolean = false;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    private dialogHelperService: DialogHelperService
  ) {
    effect(() => {
      console.log(
        'update signal triggered: ',
        this.dialogHelperService.updateSignal()
      );
      this.loadData();
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loadingSkillAreas = true;
    this.supervisorApiService.GetSkillAreas().subscribe(
      (skillAreas) => {
        this.loadingSkillAreas = false;
        this.skillAreas = skillAreas;
      },
      (err) => {
        this.loadingSkillAreas = false;
        this.alertService.error(Utils.getAjaxError(err));
      }
    );
  }
}
