/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DeletePatientPlaylist } from '../fn/supervisor/delete-patient-playlist';
import { DeletePatientPlaylist$Params } from '../fn/supervisor/delete-patient-playlist';
import { DeletePatientProgram } from '../fn/supervisor/delete-patient-program';
import { DeletePatientProgram$Params } from '../fn/supervisor/delete-patient-program';
import { DeletePatientReminder } from '../fn/supervisor/delete-patient-reminder';
import { DeletePatientReminder$Params } from '../fn/supervisor/delete-patient-reminder';
import { DeletePatientTarget } from '../fn/supervisor/delete-patient-target';
import { DeletePatientTarget$Params } from '../fn/supervisor/delete-patient-target';
import { DeletePromptOrder } from '../fn/supervisor/delete-prompt-order';
import { DeletePromptOrder$Params } from '../fn/supervisor/delete-prompt-order';
import { DeleteSkillArea } from '../fn/supervisor/delete-skill-area';
import { DeleteSkillArea$Params } from '../fn/supervisor/delete-skill-area';
import { DeleteTargetTemplate } from '../fn/supervisor/delete-target-template';
import { DeleteTargetTemplate$Params } from '../fn/supervisor/delete-target-template';
import { DeleteTargetTemplateChain } from '../fn/supervisor/delete-target-template-chain';
import { DeleteTargetTemplateChain$Params } from '../fn/supervisor/delete-target-template-chain';
import { GetAllPatients } from '../fn/supervisor/get-all-patients';
import { GetAllPatients$Params } from '../fn/supervisor/get-all-patients';
import { GetPatientPlaylists } from '../fn/supervisor/get-patient-playlists';
import { GetPatientPlaylists$Params } from '../fn/supervisor/get-patient-playlists';
import { GetPatientPrograms } from '../fn/supervisor/get-patient-programs';
import { GetPatientPrograms$Params } from '../fn/supervisor/get-patient-programs';
import { GetPatientReminders } from '../fn/supervisor/get-patient-reminders';
import { GetPatientReminders$Params } from '../fn/supervisor/get-patient-reminders';
import { GetPatientTargets } from '../fn/supervisor/get-patient-targets';
import { GetPatientTargets$Params } from '../fn/supervisor/get-patient-targets';
import { GetPromptOrders } from '../fn/supervisor/get-prompt-orders';
import { GetPromptOrders$Params } from '../fn/supervisor/get-prompt-orders';
import { GetSkillAreas } from '../fn/supervisor/get-skill-areas';
import { GetSkillAreas$Params } from '../fn/supervisor/get-skill-areas';
import { GetTargetTemplateChains } from '../fn/supervisor/get-target-template-chains';
import { GetTargetTemplateChains$Params } from '../fn/supervisor/get-target-template-chains';
import { GetTargetTemplates } from '../fn/supervisor/get-target-templates';
import { GetTargetTemplates$Params } from '../fn/supervisor/get-target-templates';
import { PatientModel } from '../models/patient-model';
import { PatientPlaylistModel } from '../models/patient-playlist-model';
import { PatientProgramModel } from '../models/patient-program-model';
import { PatientReminderModel } from '../models/patient-reminder-model';
import { PatientTargetModel } from '../models/patient-target-model';
import { PromptOrderModel } from '../models/prompt-order-model';
import { SavePatientPlaylist } from '../fn/supervisor/save-patient-playlist';
import { SavePatientPlaylist$Params } from '../fn/supervisor/save-patient-playlist';
import { SavePatientProgram } from '../fn/supervisor/save-patient-program';
import { SavePatientProgram$Params } from '../fn/supervisor/save-patient-program';
import { SavePatientReminder } from '../fn/supervisor/save-patient-reminder';
import { SavePatientReminder$Params } from '../fn/supervisor/save-patient-reminder';
import { SavePatientTarget } from '../fn/supervisor/save-patient-target';
import { SavePatientTarget$Params } from '../fn/supervisor/save-patient-target';
import { SavePromptOrder } from '../fn/supervisor/save-prompt-order';
import { SavePromptOrder$Params } from '../fn/supervisor/save-prompt-order';
import { SaveSkillArea } from '../fn/supervisor/save-skill-area';
import { SaveSkillArea$Params } from '../fn/supervisor/save-skill-area';
import { SaveTargetTemplate } from '../fn/supervisor/save-target-template';
import { SaveTargetTemplate$Params } from '../fn/supervisor/save-target-template';
import { SaveTargetTemplateChain } from '../fn/supervisor/save-target-template-chain';
import { SaveTargetTemplateChain$Params } from '../fn/supervisor/save-target-template-chain';
import { SkillAreaModel } from '../models/skill-area-model';
import { TargetTemplateChainModel } from '../models/target-template-chain-model';
import { TargetTemplateModel } from '../models/target-template-model';

@Injectable({ providedIn: 'root' })
export class SupervisorApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiSupervisorGetTargetTemplatesGet()` */
  static readonly ApiSupervisorGetTargetTemplatesGetPath = '/api/Supervisor/GetTargetTemplates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetTargetTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetTargetTemplates$Response(params?: GetTargetTemplates$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TargetTemplateModel>>> {
    return GetTargetTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetTargetTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetTargetTemplates(params?: GetTargetTemplates$Params, context?: HttpContext): Observable<Array<TargetTemplateModel>> {
    return this.GetTargetTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TargetTemplateModel>>): Array<TargetTemplateModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSaveTargetTemplatePost()` */
  static readonly ApiSupervisorSaveTargetTemplatePostPath = '/api/Supervisor/SaveTargetTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SaveTargetTemplate()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveTargetTemplate$Response(params?: SaveTargetTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<TargetTemplateModel>> {
    return SaveTargetTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SaveTargetTemplate$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveTargetTemplate(params?: SaveTargetTemplate$Params, context?: HttpContext): Observable<TargetTemplateModel> {
    return this.SaveTargetTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TargetTemplateModel>): TargetTemplateModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeleteTargetTemplatePost()` */
  static readonly ApiSupervisorDeleteTargetTemplatePostPath = '/api/Supervisor/DeleteTargetTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeleteTargetTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteTargetTemplate$Response(params?: DeleteTargetTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeleteTargetTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeleteTargetTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteTargetTemplate(params?: DeleteTargetTemplate$Params, context?: HttpContext): Observable<void> {
    return this.DeleteTargetTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetTargetTemplateChainsGet()` */
  static readonly ApiSupervisorGetTargetTemplateChainsGetPath = '/api/Supervisor/GetTargetTemplateChains';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetTargetTemplateChains()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetTargetTemplateChains$Response(params?: GetTargetTemplateChains$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TargetTemplateChainModel>>> {
    return GetTargetTemplateChains(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetTargetTemplateChains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetTargetTemplateChains(params?: GetTargetTemplateChains$Params, context?: HttpContext): Observable<Array<TargetTemplateChainModel>> {
    return this.GetTargetTemplateChains$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TargetTemplateChainModel>>): Array<TargetTemplateChainModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSaveTargetTemplateChainPost()` */
  static readonly ApiSupervisorSaveTargetTemplateChainPostPath = '/api/Supervisor/SaveTargetTemplateChain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SaveTargetTemplateChain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveTargetTemplateChain$Response(params?: SaveTargetTemplateChain$Params, context?: HttpContext): Observable<StrictHttpResponse<TargetTemplateChainModel>> {
    return SaveTargetTemplateChain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SaveTargetTemplateChain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveTargetTemplateChain(params?: SaveTargetTemplateChain$Params, context?: HttpContext): Observable<TargetTemplateChainModel> {
    return this.SaveTargetTemplateChain$Response(params, context).pipe(
      map((r: StrictHttpResponse<TargetTemplateChainModel>): TargetTemplateChainModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeleteTargetTemplateChainPost()` */
  static readonly ApiSupervisorDeleteTargetTemplateChainPostPath = '/api/Supervisor/DeleteTargetTemplateChain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeleteTargetTemplateChain()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteTargetTemplateChain$Response(params?: DeleteTargetTemplateChain$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeleteTargetTemplateChain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeleteTargetTemplateChain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteTargetTemplateChain(params?: DeleteTargetTemplateChain$Params, context?: HttpContext): Observable<void> {
    return this.DeleteTargetTemplateChain$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetPromptOrdersGet()` */
  static readonly ApiSupervisorGetPromptOrdersGetPath = '/api/Supervisor/GetPromptOrders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPromptOrders()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPromptOrders$Response(params?: GetPromptOrders$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PromptOrderModel>>> {
    return GetPromptOrders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPromptOrders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPromptOrders(params?: GetPromptOrders$Params, context?: HttpContext): Observable<Array<PromptOrderModel>> {
    return this.GetPromptOrders$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PromptOrderModel>>): Array<PromptOrderModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSavePromptOrderPost()` */
  static readonly ApiSupervisorSavePromptOrderPostPath = '/api/Supervisor/SavePromptOrder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePromptOrder()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePromptOrder$Response(params?: SavePromptOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<PromptOrderModel>> {
    return SavePromptOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePromptOrder$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePromptOrder(params?: SavePromptOrder$Params, context?: HttpContext): Observable<PromptOrderModel> {
    return this.SavePromptOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<PromptOrderModel>): PromptOrderModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeletePromptOrderPost()` */
  static readonly ApiSupervisorDeletePromptOrderPostPath = '/api/Supervisor/DeletePromptOrder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeletePromptOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePromptOrder$Response(params?: DeletePromptOrder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeletePromptOrder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeletePromptOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePromptOrder(params?: DeletePromptOrder$Params, context?: HttpContext): Observable<void> {
    return this.DeletePromptOrder$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetSkillAreasGet()` */
  static readonly ApiSupervisorGetSkillAreasGetPath = '/api/Supervisor/GetSkillAreas';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetSkillAreas()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSkillAreas$Response(params?: GetSkillAreas$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SkillAreaModel>>> {
    return GetSkillAreas(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetSkillAreas$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetSkillAreas(params?: GetSkillAreas$Params, context?: HttpContext): Observable<Array<SkillAreaModel>> {
    return this.GetSkillAreas$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SkillAreaModel>>): Array<SkillAreaModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSaveSkillAreaPost()` */
  static readonly ApiSupervisorSaveSkillAreaPostPath = '/api/Supervisor/SaveSkillArea';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SaveSkillArea()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveSkillArea$Response(params?: SaveSkillArea$Params, context?: HttpContext): Observable<StrictHttpResponse<SkillAreaModel>> {
    return SaveSkillArea(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SaveSkillArea$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SaveSkillArea(params?: SaveSkillArea$Params, context?: HttpContext): Observable<SkillAreaModel> {
    return this.SaveSkillArea$Response(params, context).pipe(
      map((r: StrictHttpResponse<SkillAreaModel>): SkillAreaModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeleteSkillAreaPost()` */
  static readonly ApiSupervisorDeleteSkillAreaPostPath = '/api/Supervisor/DeleteSkillArea';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeleteSkillArea()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteSkillArea$Response(params?: DeleteSkillArea$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeleteSkillArea(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeleteSkillArea$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeleteSkillArea(params?: DeleteSkillArea$Params, context?: HttpContext): Observable<void> {
    return this.DeleteSkillArea$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetAllPatientsGet()` */
  static readonly ApiSupervisorGetAllPatientsGetPath = '/api/Supervisor/GetAllPatients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetAllPatients()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllPatients$Response(params?: GetAllPatients$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientModel>>> {
    return GetAllPatients(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetAllPatients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetAllPatients(params?: GetAllPatients$Params, context?: HttpContext): Observable<Array<PatientModel>> {
    return this.GetAllPatients$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientModel>>): Array<PatientModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetPatientProgramsGet()` */
  static readonly ApiSupervisorGetPatientProgramsGetPath = '/api/Supervisor/GetPatientPrograms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientPrograms()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientPrograms$Response(params?: GetPatientPrograms$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientProgramModel>>> {
    return GetPatientPrograms(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientPrograms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientPrograms(params?: GetPatientPrograms$Params, context?: HttpContext): Observable<Array<PatientProgramModel>> {
    return this.GetPatientPrograms$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientProgramModel>>): Array<PatientProgramModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSavePatientProgramPost()` */
  static readonly ApiSupervisorSavePatientProgramPostPath = '/api/Supervisor/SavePatientProgram';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientProgram()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientProgram$Response(params?: SavePatientProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientProgramModel>> {
    return SavePatientProgram(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientProgram$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientProgram(params?: SavePatientProgram$Params, context?: HttpContext): Observable<PatientProgramModel> {
    return this.SavePatientProgram$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientProgramModel>): PatientProgramModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeletePatientProgramPost()` */
  static readonly ApiSupervisorDeletePatientProgramPostPath = '/api/Supervisor/DeletePatientProgram';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeletePatientProgram()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientProgram$Response(params?: DeletePatientProgram$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeletePatientProgram(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeletePatientProgram$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientProgram(params?: DeletePatientProgram$Params, context?: HttpContext): Observable<void> {
    return this.DeletePatientProgram$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetPatientPlaylistsGet()` */
  static readonly ApiSupervisorGetPatientPlaylistsGetPath = '/api/Supervisor/GetPatientPlaylists';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientPlaylists()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientPlaylists$Response(params?: GetPatientPlaylists$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientPlaylistModel>>> {
    return GetPatientPlaylists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientPlaylists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientPlaylists(params?: GetPatientPlaylists$Params, context?: HttpContext): Observable<Array<PatientPlaylistModel>> {
    return this.GetPatientPlaylists$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientPlaylistModel>>): Array<PatientPlaylistModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSavePatientPlaylistPost()` */
  static readonly ApiSupervisorSavePatientPlaylistPostPath = '/api/Supervisor/SavePatientPlaylist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientPlaylist()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientPlaylist$Response(params?: SavePatientPlaylist$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientPlaylistModel>> {
    return SavePatientPlaylist(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientPlaylist$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientPlaylist(params?: SavePatientPlaylist$Params, context?: HttpContext): Observable<PatientPlaylistModel> {
    return this.SavePatientPlaylist$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientPlaylistModel>): PatientPlaylistModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeletePatientPlaylistPost()` */
  static readonly ApiSupervisorDeletePatientPlaylistPostPath = '/api/Supervisor/DeletePatientPlaylist';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeletePatientPlaylist()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientPlaylist$Response(params?: DeletePatientPlaylist$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeletePatientPlaylist(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeletePatientPlaylist$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientPlaylist(params?: DeletePatientPlaylist$Params, context?: HttpContext): Observable<void> {
    return this.DeletePatientPlaylist$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetPatientTargetsGet()` */
  static readonly ApiSupervisorGetPatientTargetsGetPath = '/api/Supervisor/GetPatientTargets';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientTargets()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientTargets$Response(params?: GetPatientTargets$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientTargetModel>>> {
    return GetPatientTargets(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientTargets$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientTargets(params?: GetPatientTargets$Params, context?: HttpContext): Observable<Array<PatientTargetModel>> {
    return this.GetPatientTargets$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientTargetModel>>): Array<PatientTargetModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSavePatientTargetPost()` */
  static readonly ApiSupervisorSavePatientTargetPostPath = '/api/Supervisor/SavePatientTarget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientTarget()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientTarget$Response(params?: SavePatientTarget$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientTargetModel>> {
    return SavePatientTarget(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientTarget$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientTarget(params?: SavePatientTarget$Params, context?: HttpContext): Observable<PatientTargetModel> {
    return this.SavePatientTarget$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientTargetModel>): PatientTargetModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeletePatientTargetPost()` */
  static readonly ApiSupervisorDeletePatientTargetPostPath = '/api/Supervisor/DeletePatientTarget';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeletePatientTarget()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientTarget$Response(params?: DeletePatientTarget$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeletePatientTarget(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeletePatientTarget$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientTarget(params?: DeletePatientTarget$Params, context?: HttpContext): Observable<void> {
    return this.DeletePatientTarget$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiSupervisorGetPatientRemindersGet()` */
  static readonly ApiSupervisorGetPatientRemindersGetPath = '/api/Supervisor/GetPatientReminders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientReminders()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientReminders$Response(params?: GetPatientReminders$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientReminderModel>>> {
    return GetPatientReminders(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientReminders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientReminders(params?: GetPatientReminders$Params, context?: HttpContext): Observable<Array<PatientReminderModel>> {
    return this.GetPatientReminders$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientReminderModel>>): Array<PatientReminderModel> => r.body)
    );
  }

  /** Path part for operation `apiSupervisorSavePatientReminderPost()` */
  static readonly ApiSupervisorSavePatientReminderPostPath = '/api/Supervisor/SavePatientReminder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientReminder()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientReminder$Response(params?: SavePatientReminder$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientReminderModel>> {
    return SavePatientReminder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientReminder$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientReminder(params?: SavePatientReminder$Params, context?: HttpContext): Observable<PatientReminderModel> {
    return this.SavePatientReminder$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientReminderModel>): PatientReminderModel => r.body)
    );
  }

  /** Path part for operation `apiSupervisorDeletePatientReminderPost()` */
  static readonly ApiSupervisorDeletePatientReminderPostPath = '/api/Supervisor/DeletePatientReminder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `DeletePatientReminder()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientReminder$Response(params?: DeletePatientReminder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return DeletePatientReminder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `DeletePatientReminder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  DeletePatientReminder(params?: DeletePatientReminder$Params, context?: HttpContext): Observable<void> {
    return this.DeletePatientReminder$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
