import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-box-anecdotal',
  templateUrl: './box-anecdotal.component.html',
})
export class BoxAnecdotalComponent implements AfterViewInit {
  @Input() text: string|undefined = '-';
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  @Input() expectedAmount: number = 0;    
  anecdotals: any[] = [];

  extraOpened: boolean = false;
  currentAnecdotalIndex: number = 0;
  extraBoxes: ElementRef[] = [];

  @ViewChild('anecdotalBox', { static: true }) anecdotalBox!: ElementRef;
  @ViewChild('anecdotalExtraBox', { static: true }) anecdotalExtraBox!: ElementRef;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    // Initialize the position of the dtt-extra-container
    const anecdotalContainer = this.anecdotalBox.nativeElement;
    const extraContainer = this.anecdotalExtraBox.nativeElement;
    const body = document.querySelector('body') as HTMLElement;

    if (anecdotalContainer && extraContainer) {
      // Append the extra container to the anecdotal container
      body.appendChild(extraContainer);
      this.updateExtraContainerPosition(anecdotalContainer, extraContainer);

    }

    // Update position on window resize
    window.addEventListener('resize', () => {
        this.updateExtraContainerPosition(anecdotalContainer, extraContainer);
    });
    // Update position on scroll
    window.addEventListener('scroll', () => {
          this.updateExtraContainerPosition(anecdotalContainer, extraContainer);
    });
    const scrollableElements = document.querySelectorAll('div');
    scrollableElements.forEach(element => {
      element.addEventListener('scroll', () => {
        this.updateExtraContainerPosition(anecdotalContainer, extraContainer);
      });
    });

    // Observe changes in the DOM that might affect positioning
    const observer = new MutationObserver(() => {
        this.updateExtraContainerPosition(anecdotalContainer, extraContainer);
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true
    });
    this.extraBoxes = [this.anecdotalExtraBox];
    this.cdRef.detectChanges();
  }


  getExtraBoxes(): ElementRef[] {
    return [this.anecdotalExtraBox];
  }
  onClickOutside() {
    if (this.extraOpened) {
      this.anecdotalExtraBox.nativeElement.classList.add('hide');
      this.extraOpened = false;
    }
  }

  private updateExtraContainerPosition(dttContainer: HTMLElement, extraContainer: HTMLElement): void {
    const top = dttContainer.offsetTop;
    const rect = dttContainer.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const containerRight = rect.right;
    const spaceOnRight = windowWidth - containerRight;
    const spaceOnLeft = rect.left;
    const extraContainerWidth = 180; // Width of the extra container

    if (spaceOnRight < extraContainerWidth + 50 && spaceOnLeft >= extraContainerWidth + 50) {
      // Position to the left if there's not enough space on the right
      extraContainer.style.left = `${rect.left - extraContainerWidth - 10}px`;
    } else {
      // Default positioning to the right
      extraContainer.style.left = `${rect.left + rect.width + 10}px`;
    }

    extraContainer.style.top = `${top}px`;
  }


  showExtra(): void {
    this.extraOpened = !this.extraOpened;
    if (this.extraOpened) {
      // Hide all other extra containers
      const allExtraContainers = document.querySelectorAll('.extra-container');
      allExtraContainers.forEach((container: Element) => {
        if (container instanceof HTMLElement && container !== this.anecdotalExtraBox.nativeElement) {
          container.classList.add('hide');
        }
      });

      // Ensure the current extra container is visible
      this.anecdotalExtraBox.nativeElement.classList.remove('hide');

      // Update the position of the extra container
      setTimeout(() => {
        const anecdotalContainer = this.anecdotalBox.nativeElement;
        const extraContainer = this.anecdotalExtraBox.nativeElement;
        this.updateExtraContainerPosition(anecdotalContainer, extraContainer);
      });
    }
  }
  
  closeExtra() {
    this.extraOpened = false;
  }

  addOrOpenAnecdotal() {
    if (this.anecdotals.length > 0) {
      this.showExtra();
    } else {
      this.addAnecdotal();
      this.showExtra();
    }
  }

  addAnecdotal() {
    const currentDate = new Date();
    const formattedDate = this.formatDate(currentDate);
    this.anecdotals.push({ text: '', date: formattedDate });
    this.currentAnecdotalIndex = this.anecdotals.length - 1;
  }

  currentAnecdotal() {
    if (this.anecdotals.length == 0) {
      return { text: '', date: '' };
    }
    return this.anecdotals[this.currentAnecdotalIndex];
  }

  saveAnecdotal() {
    //TODO: save anecdotal to database
  }

  private formatDate(date: Date): string {
    const today = new Date();
    if (date.toDateString() === today.toDateString()) {
      return 'at ' + date.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }).toLowerCase();
    } else {
      return 'on ' + date.toLocaleString('en-US', {
        day: 'numeric',
        month: 'short',
        year: '2-digit'
      });
    }
  }

  selectAnecdotal(index: number) {
    this.currentAnecdotalIndex = index;
  }
}