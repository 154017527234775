<div #echoicBox [class.top-box]="isTopBox" [class.bottom-box]="isBottomBox" class="echoic-box pointer"
(clickOutside)="onClickOutside()"
[additionalElementRefs]="extraBoxes">
    <div class="flag flex-column  no-select" (click)="showExtra()"
        [class.flag-all-trials]="selectedOptions.length >= requiredTrials">
        {{text}}
        <button class="button-program" *ngIf="isBottomBox" [style.background-color]="currentLevel.color"
            [style.border-color]="currentLevel.color">{{currentLevel.name}}</button>
        <!-- <button class="badge-bottom-left">?</button> -->
        <span *ngIf="selectedOption" class="badge-count" [style.color]="selectedOption.color"
            [style.border-color]="selectedOption.color">{{totalSuccess}}/{{selectedOptions.length}}</span>
        <span *ngIf="selectedOption" class="badge badge-left echoic-option-icon" [style.color]="selectedOption.color"
            [style.border-color]="selectedOption.color">
            {{ selectedOption.icon }}
        </span>
    </div>
</div>

<div class="extra-container" #echoicExtraBox [class.hide]="!extraOpened" [class.big-extra-container]="isRecording || isPlayingVideo">
    <div class="extra-close" (click)="closeExtra()">X</div>
    <div class="dtt-container-textbox">
        <div [class.hide]="justSelectedOption">
            <div *ngFor="let option of options" class="pointer" (click)="selectOption(option)">
                <div class="dtt-option" [style.background-color]="option.color">
                    <span class="dtt-option-icon" [style.color]="option.color">{{ option.icon }}</span>
                    <span class="dtt-option-name">{{ option.name }}</span>
                </div>
            </div>
        </div>
        <div [class.hide]="!justSelectedOption">
            <div [class.hide]="isRecording || isPlayingVideo">
                <div *ngFor="let rate of rates" class="pointer" (click)="selectRate(rate)">
                    <div class="dtt-option" [style.background-color]="rate.color">
                        <span class="dtt-option-icon" [style.color]="rate.color">{{ rate.icon }}</span>
                        <span class="dtt-option-name">{{ rate.name }}</span>
                    </div>
                </div>
            </div>
            <div class="dtt-option justify-content-center pointer"  [class.hide]="isRecording">
                <span *ngIf="!isPlayingVideo" class="dtt-option-icon" [style.color]="'#5DADE2'"  title="Record" (click)="record()">R</span>
                <span [class.hide]="isPlayingVideo" *ngIf="justSelectedOption && selectedOption?.videoBlob" class="dtt-option-name" [style.color]="'#5DADE2'" (click)="playVideo()">See video</span>
                
                <video id="recordedVideo" width="320" height="240" controls class="hide" [src]="selectedOption?.videoUrl"> </video>
            </div>
            <div [class.hide]="!isRecording">
                <div class="dtt-option justify-content-center pointer" (click)="stopRecording()">
                    <span class="dtt-option-icon" [style.color]="'#5DADE2'" title="Stop">S</span>
                </div>
                <video id="recordingVideo" width="320" height="240" controls></video>
            </div>
        </div>
    </div>
</div>