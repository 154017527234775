<div #dttBox [class.top-box]="isTopBox" [class.bottom-box]="isBottomBox" class="dtt-box pointer" 
     (clickOutside)="onClickOutside()"
     [additionalElementRefs]="extraBoxes">
    <div class="flag flex-column  no-select" (click)="showExtra()" [class.flag-all-trials]="selectedOptions.length >= requiredTrials">
        {{text}}
        <button class="button-program" *ngIf="isBottomBox" [style.background-color]="currentLevel.color" [style.border-color]="currentLevel.color">{{currentLevel.name}}</button>
        <!-- <button class="badge-bottom-left">?</button> -->
        <span *ngIf="selectedOption" class="badge-count" [style.color]="selectedOption.color" [style.border-color]="selectedOption.color">{{totalSuccess}}/{{selectedOptions.length}}</span>
        <span *ngIf="selectedOption" class="badge badge-left dtt-option-icon" [style.color]="selectedOption.color" [style.border-color]="selectedOption.color">
            {{ selectedOption.icon }}
        </span>
    </div>
</div>

<div class="extra-container" #dttExtraBox [class.hide]="!extraOpened">
    <div class="extra-close" (click)="closeExtra()">X</div>
    <div class="extra-container-textbox">
        <div *ngFor="let option of options" class="pointer" (click)="selectOption(option)">
            <div class="dtt-option" [style.background-color]="option.color">
                <span class="dtt-option-icon" [style.color]="option.color">{{ option.icon }}</span>
                <span class="dtt-option-name">{{ option.name }}</span>
            </div>
        </div>
    </div>
</div>