import { Component, Input } from '@angular/core';

import {
  PatientProgramModel,
  PatientTargetModel,
  TargetTemplateModel,
} from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { DialogHelperService } from '../../dialog.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';

@Component({
  selector: 'app-target',
  templateUrl: './target.component.html',
  styleUrl: '../../supervisor-main.component.css',
})
export class TargetComponent {
  @Input() program: PatientProgramModel | undefined;
  @Input() target: PatientTargetModel | undefined;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private dialogHelperService: DialogHelperService,
    private alertService: AlertService
  ) {}

  isCollapsed = true;

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  editTarget() {
    console.log('editTarget clicked!');
    this.dialogHelperService.additTargetButtonClicked(this.target ?? null);
  }

  get targetIncludedPlaylistNames() {
    return 'Morning Routine, Evening Routine, etc.';
  }

  get targetSkillAreasNames() {
    return 'Adaptive, Communication, etc.';
  }

  deleteTarget() {
    console.log('deleteTarget clicked!');
    if (this.target?.ID != null) {
      this.supervisorApiService
        .DeletePatientTarget({ patientTargetID: this.target.ID })
        .subscribe(
          () => {
            this.alertService.success('Program deleted successfully');
            this.dialogHelperService.onCloseAddPlaylist();
          },
          (error) => {
            console.error(error);
            this.alertService.error(Utils.getAjaxError(error));
          }
        );
    }
  }
}
