import { NgModule, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { SharedModule } from '../shared/shared.module';
import { AuthGuard } from '../core/auth.guard';
import { AppRoutingModule } from '../app-routing.module';
import { MessageService } from 'primeng/api';

import { StaffRoutingModule } from './staff.routing.module';
import {
  StaffMainComponent,
  StaffHomeComponent
} from './';
import { BoxFrequencyComponent } from './home/box-frequency/box-frequency.component';
import { BoxDurationComponent } from './home/box-duration/box-duration.component';
import { BoxDttComponent } from './home/box-dtt/box-dtt.component';
import { BoxQuantityComponent } from './home/box-quantity/box-quantity.component';
import { BoxFluencyrateComponent } from './home/box-fluencyrate/box-fluencyrate.component';
import { BoxAnecdotalComponent } from './home/box-anecdotal/box-anecdotal.component';
import { BoxEchoicComponent } from './home/box-echoic/box-echoic.component';
import { PatientSessionService } from './home/patient-session.service';

function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(`${moduleName} has already been loaded. Import Core modules in the AppModule only.`);
  }
}


const pages = [

  StaffMainComponent,
  StaffHomeComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    StaffRoutingModule
  ],
  declarations: [
    ...pages,
    BoxFrequencyComponent,
    BoxDurationComponent,
    BoxDttComponent,
    BoxQuantityComponent,
    BoxFluencyrateComponent,
    BoxAnecdotalComponent,
    BoxEchoicComponent
  ],
  exports: [ ],
  providers: [PatientSessionService],
  bootstrap: []
})
export class StaffModule { 
  constructor(@Optional() @SkipSelf() parentModule: StaffModule) {
    if (parentModule) {
      throw new Error('Module is already loaded. Import in your base AppModule only.');
    }
  }
}
