<div class="horizontal-flex-list">
    <div class="form-check">
        <input class="form-check-input checkmarks-check" type="checkbox" value="" id="archived" >
        <label class="form-check-label checkmarks-label" for="archived">
            Archived
        </label>
    </div>
    <div class="form-check">
        <input class="form-check-input checkmarks-check" type="checkbox" value="" id="mastered" >
        <label class="form-check-label checkmarks-label" for="mastered">
            Mastered
        </label>
    </div>
    <div class="form-check">
        <input class="form-check-input checkmarks-check" type="checkbox" value="" id="onHold" >
        <label class="form-check-label checkmarks-label" for="onHold">
            On Hold
        </label>
    </div>
    <div class="form-check">
        <input class="form-check-input checkmarks-check" type="checkbox" value="" id="ready" >
        <label class="form-check-label checkmarks-label" for="ready">
            Ready
        </label>
    </div>
</div>
<div
    class="horizontal-flex-list"
>
        <a class="skill-area">
            <label>Sort by trial type</label>
        </a>
        <div class="horizontal-flex-list">
            <label class="checkmarks-label">Search</label>
            <input class="search-field" type="search" name="" id="">
        </div>
        
</div>

<div class="horizontal-flex-list">
    <a class="skill-area">
        <label>Adaptive</label>
    </a>
    <a class="skill-area">
        <label>Skill Area 2</label>
    </a>
    <a class="skill-area">
        <label>Skill Area 3</label>
    </a>
    <a class="skill-area">
        <label>Skill Area 4</label>
    </a>
    <a class="skill-area">
        <label>Behaviors</label>
    </a>
</div>
<div>
    @if (loading) {
        <app-loading-indicator />
    } @else {
        @if (patientPrograms) {
            @for (program of patientPrograms; track program.ID) {
                <app-program [program]="program" />
            } @empty {
                <p style="text-align: center; margin-top: 20px; font-size: 1rem; color: #719eb6;">There are no programs yet.</p>
            }
        } @else {
            <p style="text-align: center; margin-top: 20px; font-size: 1rem; color: #719eb6;">Programs are not available.</p>
        }
    }
    
</div>
    
