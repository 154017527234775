import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import { UserModel } from "./api/models";

export class Utils {


    static getErrorMessage(validatorName: string, validatorValue: any): string {
        switch (validatorName) {
            case 'required':
                return 'This field is required.';
            case 'minlength':
                return `Minimum length is ${validatorValue.requiredLength}.`;
            case 'maxlength':
                return `Maximum length is ${validatorValue.requiredLength}.`;
            case 'email':
                return `The field shall be a valid email.`;
            // Add more cases for other validators you might have
            default:
                return `Validation failed (${validatorName}).`;
        }
    }

    static getFormErrors(form: FormGroup, parentControlName?: string): string[] {
        var errors: string[] = [];
        if (!form)
        return errors;
      if (!parentControlName)
        parentControlName = '';
        for (let controlName in form.controls) {
          let control = form.controls[controlName];
          if (control.status === 'INVALID') {
            let formArray = control as FormArray;
            if (formArray && formArray.controls && formArray.controls.length>0) {
              for (let subFormName in formArray.controls) {
                errors.push(...Utils.getFormErrors(formArray.controls[subFormName] as FormGroup, `${parentControlName}${controlName}[${subFormName}].`));
              }
            }
            else
            {
            
              for (let validatorName in control.errors) {
                //errorMessages.push(Utils.getErrorMessage(validatorName, control.errors[validatorName]));
                switch (validatorName) {
                  case 'required':
                    errors.push(`The field "${parentControlName}${controlName}" is required.`);
                    break;
                  case 'minlength':
                    errors.push(`The field "${parentControlName}${controlName}" minimum length is ${control.errors[validatorName].requiredLength}.`);
                    break;
                  case 'maxlength':
                    errors.push(`The field "${parentControlName}${controlName}" maximum length is ${control.errors[validatorName].requiredLength}.`);
                    break;
                  case 'email':
                    errors.push(`The field "${parentControlName}${controlName}" shall be a valid email.`);
                    break;
                  default:
                    errors.push(`The field "${parentControlName}${controlName}" is invalid.`);
                }
              }
            
            }
          }
        }
        return errors;
    }


  static getAjaxError(err: any): string {

    var errMsg = 'Error';
    if (typeof (err) == 'string')
      errMsg = `${err}`;
    else {
      

      errMsg = (err.error && err.error.message) ? err.error.message :
        (err.errors && err.errors.model && err.errors.model.length) ? err.errors.model[0] :
          (err.title) ? err.title :
            (err.error) ? err.error :
              `Error performing request, status code = ${err.status} ${err.statusText}`;
      if (err.errors) {
        for (var k in err.errors) {
          if (typeof (err.errors[k]) == 'string') {
            errMsg = err.errors[k];
            break;
          }
          else if (err.errors[k].length && typeof (err.errors[k][0]) == 'string') {
            errMsg = err.errors[k][0];
            break;
          }
        }
      }
    }
    return errMsg;
  }
  

    static getDateFromISODate(dateStr: string): Date {
        const dateSplitted = dateStr.split('-');
        return new Date(Number(dateSplitted[0]), Number(dateSplitted[1]) - 1, Number(dateSplitted[2]));
    }


    static deepClone(obj: any) {
        return JSON.parse(JSON.stringify(obj));
    }

    static getCaretPosition(editableDiv: HTMLElement) {
        var caretPos = 0,
          sel:Selection|null, range;
        if (window.getSelection) {
          sel = window.getSelection();
          if (sel && sel.rangeCount) {
            range = sel.getRangeAt(0);
            if (range.commonAncestorContainer.parentNode == editableDiv) {
              caretPos = range.endOffset;
            }
          }
        } else if ((document as any).selection && (document as any).selection.createRange) {
          range = (document as any).selection.createRange();
          if (range.parentElement() == editableDiv) {
            var tempEl = document.createElement("span");
            editableDiv.insertBefore(tempEl, editableDiv.firstChild);
            var tempRange = range.duplicate();
            tempRange.moveToElementText(tempEl);
            tempRange.setEndPoint("EndToEnd", range);
            caretPos = tempRange.text.length;
          }
        }
        return caretPos;
      }

      static addMinutes(date: Date, minutes: number) {
        return new Date(date.getTime() + minutes*60000);
  }
  static parseJwt(token: string | null | undefined) {
    if (!token)
      return {};
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

}

declare global {
    interface Date {
        toISODate(): string;
        toSimpleDate(): string;
    }
}

Object.defineProperty(Date.prototype, 'toISODate', {
    value: function () {
        return this.getFullYear() + '-' +
            ('0' + (this.getMonth() + 1)).slice(-2) + '-' +
            ('0' + this.getDate()).slice(-2);
    }
});

Object.defineProperty(Date.prototype, 'toSimpleDate', {
  value: function () {
      return ('0' + (this.getMonth() + 1)).slice(-2) + '/' +
      ('0' + this.getDate()).slice(-2) + '/' +
           this.getFullYear();
  }
});
