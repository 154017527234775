import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-box-echoic',
  templateUrl: './box-echoic.component.html',

})
export class BoxEchoicComponent implements AfterViewInit {
  @Input() text: string|undefined = '-';
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  
  constructor(private cdRef: ChangeDetectorRef) {}
  levels = [
    {
      name: 'Probe',
      color: '#DFE2AF',
    },
    {
      name: 'Acquisition',
      color: '#a87553',
    },
    {
      name: 'Mastered',
      color: '#6f9c61',
    }
  ];
  options = [
    {
      name: 'Full Physical',
      icon: 'F',
      color: '#C15757',
    },
    {
      name: 'Partial Physical',
      icon: 'P',
      color: '#C15757',
    },
    {
      name: 'Model',
      icon: 'M',
      color: '#CC9E7F',
    },
    {
      name: 'Gesture',
      icon: 'G',
      color: '#8AC379',
      isSuccess: true,
    },
    {
      name: 'Independent',
      icon: '+',
      color: '#8AC379',
      isSuccess: true,
    }
    
  ];

  rates = [
    {
      name: 'Articulation',
      icon: '0',
      color: '#5DADE2',
    },
    {
      name: 'Volume',
      icon: '.5',
      color: '#5DADE2',
    },
    {
      name: 'Speed',
      icon: 'NA',
      color: '#5DADE2',
    },
    {
      name: 'Tone',
      icon: '1',
      color: '#5DADE2',
    },
    {
      name: 'Rhythm',
      icon: '1',
      color: '#5DADE2',
    }
  ];
  


  extraOpened: boolean = false;
  selectedOption: any;
  justSelectedOption: any;
  selectedRate: any;
  isPlayingVideo: boolean = false;
  @Input() requiredTrials: number = 3;
  @Input() totalSuccess: number = 0;
  @Input() selectedOptions: any[] = [];
  @Input() currentLevel: any = this.levels[0];
  extraBoxes: ElementRef[] = [];
  @ViewChild('echoicBox', { static: true }) echoicBox!: ElementRef;
  @ViewChild('echoicExtraBox', { static: true }) echoicExtraBox!: ElementRef;

  ngAfterViewInit() {
    // Initialize the position of the echoic-extra-container
    const echoicContainer = this.echoicBox.nativeElement;
    const extraContainer = this.echoicExtraBox.nativeElement;
    const body = document.querySelector('body') as HTMLElement;

    if (echoicContainer && extraContainer) {
      // Append the extra container to the echoic container
      body.appendChild(extraContainer);
      this.updateExtraContainerPosition(echoicContainer, extraContainer);

    }

    // Update position on window resize
    window.addEventListener('resize', () => {
      this.updateExtraContainerPosition(echoicContainer, extraContainer);
    });
    // Update position on scroll
    window.addEventListener('scroll', () => {
      this.updateExtraContainerPosition(echoicContainer, extraContainer);
    });
    const scrollableElements = document.querySelectorAll('div');
    scrollableElements.forEach(element => {
      element.addEventListener('scroll', () => {
        this.updateExtraContainerPosition(echoicContainer, extraContainer);
      });
    });

    // Observe changes in the DOM that might affect positioning
    const observer = new MutationObserver(() => {
      this.updateExtraContainerPosition(echoicContainer, extraContainer);
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true
    });
  
    this.extraBoxes = [this.echoicExtraBox];
    this.cdRef.detectChanges();
  }

  onClickOutside() {
    this.extraOpened = false;
  }

  private updateExtraContainerPosition(echoicContainer: HTMLElement, extraContainer: HTMLElement): void {
    const top = echoicContainer.offsetTop;
    const rect = echoicContainer.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const containerRight = rect.right;
    const spaceOnRight = windowWidth - containerRight;
    const spaceOnLeft = rect.left;
    const extraContainerWidth = 180; // Width of the extra container

    if (spaceOnRight < extraContainerWidth + 50 && spaceOnLeft >= extraContainerWidth + 50) {
      // Position to the left if there's not enough space on the right
      extraContainer.style.left = `${rect.left - extraContainerWidth - 10}px`;
    } else {
      // Default positioning to the right
      extraContainer.style.left = `${rect.left + rect.width + 10}px`;
    }

    extraContainer.style.top = `${top}px`;
  }


  showExtra(): void {
    this.justSelectedOption = null;
    this.selectedOption = null;
    this.selectedRate = null;
    this.isPlayingVideo = false;
    this.isRecording = false;
    
    this.extraOpened = !this.extraOpened;
    if (this.extraOpened) {
      // Hide all other extra containers
      const allExtraContainers = document.querySelectorAll('.extra-container');
      allExtraContainers.forEach((container: Element) => {
        if (container instanceof HTMLElement && container !== this.echoicExtraBox.nativeElement) {
          container.classList.add('hide');
        }
      });

      // Ensure the current extra container is visible
      this.echoicExtraBox.nativeElement.classList.remove('hide');

      // Update the position of the extra container
      setTimeout(() => {
        const echoicContainer = this.echoicBox.nativeElement;
        const extraContainer = this.echoicExtraBox.nativeElement;
        this.updateExtraContainerPosition(echoicContainer, extraContainer);
      });
    }
  }
  
  closeExtra() {
    this.extraOpened = false;
  }

  selectOption(option: any) {
    // Store the selected option
    this.selectedOption = option;
    this.justSelectedOption = option;

    // Initialize the selectedOptions array if it doesn't exist
    if (!this.selectedOptions) {
      this.selectedOptions = [];
    }

    if (option.isSuccess) {
      this.totalSuccess++;
    }

    // Add the selected option to the array
    this.selectedOptions.push(option);

  }

  selectRate(rate: any) {
    this.selectedRate = rate;
    // Close the extra container after selection
    this.closeExtra();
  }
  
  private videoStream: MediaStream | null = null;
  private mediaRecorder: MediaRecorder | null = null;
  private recordedChunks: Blob[] = [];
  isRecording: boolean = false;

  record() {
    this.isRecording = true;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.videoStream = stream;
          const videoElement = document.querySelector('video#recordingVideo') as HTMLVideoElement;
          if (videoElement) {
            videoElement.srcObject = stream;
            videoElement.play();
          }

          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
            if (event.data.size > 0) {
              this.recordedChunks.push(event.data);
            }
          };

          this.mediaRecorder.onstop = () => {
            const blob = new Blob(this.recordedChunks, { type: 'video/webm' });
            if (this.selectedOption) {
              this.selectedOption.videoBlob = blob;
              this.selectedOption.videoUrl = URL.createObjectURL(blob);
            } else {
              console.error('No selected option to attach the video to.');
            }
          };

          this.mediaRecorder.start();
        })
        .catch(error => {
          console.error('Error accessing media devices.', error);
        });
    } else {
      console.error('getUserMedia not supported on your browser!');
    }
  }

  stopRecording() {
    this.isRecording = false;
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
      this.videoStream?.getTracks().forEach(track => track.stop());
    }
  }

  playVideo() {
    const videoElement = document.querySelector('video#recordedVideo') as HTMLVideoElement;
    if (videoElement) {
      this.isPlayingVideo = true;
      videoElement.play();
      videoElement.classList.remove('hide');
      videoElement.onended = () => {
        videoElement.classList.add('hide');
        this.isPlayingVideo = false;
      };
    }
  }
}