import { Component, computed, effect, input, OnInit, Signal, WritableSignal } from '@angular/core';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';

import { SupervisorApiService } from '../../core/api/services';
import { PatientProgramModel } from '../../core/api/models';
import { DialogHelperService } from '../dialog.helper.service';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrl: '../supervisor-main.component.css'
})
export class ProgramsComponent implements OnInit {

  patientId = input.required<number>();

  public loading = false;

  patientPrograms: PatientProgramModel[] = [];

  constructor(
    private supervisorApiService: SupervisorApiService,
    private dialogHelperService: DialogHelperService,
  ) {
    effect(() => {
      console.log('update signal triggered: ', this.dialogHelperService.updateSignal());
      this.loadData();
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading = true;

    this.supervisorApiService.GetPatientPrograms({ patientID: this.patientId() }).subscribe(
      (patientPrograms) => {
        console.log(patientPrograms);
        this.patientPrograms = patientPrograms;
        this.loading = false;
      },
      (error) => {
        console.error(error);
        this.loading = false;
      }
    );

  }



  searchProgram($event: AutoCompleteCompleteEvent) {
    console.log("searchProgram")
  }

}
