@if(isLoading) {
    <app-loading-indicator />
}
@else {
    @if (isCollapsed) {
        <div class="container" (click)="toggleCollapsed()">
            <div class="row h-4rem align-items-center">
                <div class="col-3">
                    <button type="button" class="btn btn-primary position-relative program-name">
                        {{ playlistNameDisplayName }}
                    </button>
                </div>
                <div class="col-1">
                    <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6;"></i>
                </div>
                <div class="col-3">
                    <span class="fs-8 fw-bolder" style="color: #719eb6;"> Included programs/targets: </span>
                    <span class="fs-8 " style="color: #719eb6;"> {{ playlistItemsNames }} </span>
                </div>
            </div>
        </div>
    } @else {
        <div class="container">
            <div class="row h-4rem align-items-center">
                <div class="col-3">
                    <button type="button" class="btn btn-primary position-relative program-name" (click)="toggleCollapsed()">
                        {{ playlistNameDisplayName }}
                    </button>
                </div>
                <div class="col-1">
                    <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6;"></i>
                </div>
                <div class="col-3">
                    <span class="fs-8 fw-bolder" style="color: #719eb6;"> Included programs/targets: </span>
                    <span class="fs-8 " style="color: #719eb6;"> {{ playlistItemsNames }} </span>
                </div>
                <div class="col-3">
                    <div class="side-menu-buttons">
                        <button (click)="editPlaylist()" class="side-menu-button2">Edit Playlist</button>
                        <button (click)="deletePlaylist()" class="switch-mode-button">Delete Playlist</button>
                    </div>
                </div>
            </div>   
            <div class="row">
                @for (item of playlist?.Items; track item.OrderNr) {
                    @if (item.PatientProgramID) {
                        <app-program [program]="programForID(item.PatientProgramID)" />
                    } @else {
                        <app-target [target]="targetForID(item.PatientTargetID!)" />
                    }
                } @empty {
                    <p>There are no programs/targets for this playlist yet.</p>
                }
            </div>
        </div>
    }
} 