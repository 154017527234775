
<h2> {{ titleName }} </h2>
<div class="overlay" *ngIf="loading">
  <div class="spinner"></div>
</div>
<form (ngSubmit)="onSubmit()">
  <p>
    <label for="name">Name</label>
    <input type="text" id="name" name="name" [(ngModel)]="enteredName" />
  </p>

  <p class="actions">
    <button type="button" (click)="onCancel()">Cancel</button>
    <button type="submit">{{ actionName }}</button>
  </p>
</form>
