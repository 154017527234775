import { Component, Input } from '@angular/core';
import { PatientProgramModel } from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { Router } from '@angular/router';
import { DialogHelperService } from '../../dialog.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrl: '../../supervisor-main.component.css',
})
export class ProgramComponent {
  @Input() program: PatientProgramModel | undefined;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private dialogHelperService: DialogHelperService,
    private alertService: AlertService
  ) {}

  isCollapsed = true;

  get playlists() {
    // if (this.program?.playlists.length === 0) {
    return 'none';
    // } else {
    //   return this.program?.playlists.join(', ')
    // }
  }

  get skillAreas() {
    // if (this.program?.skillAreas.length === 0) {
    return 'none';
    // } else {
    //   return this.program?.skillAreas.join(', ')
    // }
  }

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  editProgram() {
    console.log('editProgram clicked!');
    this.dialogHelperService.additProgramButtonClicked(this.program ?? null);
  }

  deleteProgram() {
    console.log('deleteProgram clicked!');
    if (this.program?.ID != null) {
      this.supervisorApiService
        .DeletePatientProgram({ patientProgramID: this.program.ID })
        .subscribe(
          () => {
            this.alertService.success('Program deleted successfully');
            this.dialogHelperService.onCloseAddPlaylist();
          },
          (error) => {
            console.error(error);
            this.alertService.error(Utils.getAjaxError(error));
          }
        );
    }
  }

  dismissAlertButtonClicked(alert: any) {
    console.log('dismissAlertButtonClicked clicked!');
    // if (this.program) {
    //   this.programsService.dismissAlertForProgram(alert.id, this.program.id)
    // }
  }
}
