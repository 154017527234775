<div #durationBox class="duration-box"  [class.top-box]="isTopBox" [class.bottom-box]="isBottomBox" 
     (clickOutside)="onClickOutside()"
     [additionalElementRefs]="extraBoxes">
    <div class="flag flex-column  no-select" (mousedown)="startLongPress()" (mouseup)="endLongPress()" (mouseleave)="endLongPress()" (touchstart)="startLongPress()" (touchend)="endLongPress()" (touchcancel)="endLongPress()">

        <span class="badge-time" *ngIf="isStarted">{{timerString()}}</span>
        <div class="duration-container">
            <div class="duration-controls">
                <button *ngIf="!isStarted" (click)="startTimer()" class="duration-button start-button">Start</button>
                <button *ngIf="isStarted" (click)="stopTimer()" class="duration-button stop-button">Stop</button>
            </div>
            <div class="flag-text">{{ text }}</div>
        </div>
        <span class="badge badge-left" (click)="showAllTimes()">{{ amountOfTimes }}</span>
        <!-- <button class="badge-bottom-left">?</button> -->
    </div>
</div>

<div #durationExtraBox class="extra-container"  [class.hide]="!extraOpened">
    <div class="extra-close" (click)="closeExtra()">X</div>
    <div class="extra-container-textbox">
        <div *ngFor="let time of times" class="extra-text">{{ time }}</div>
    </div>
</div>