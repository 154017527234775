
<form [formGroup]="loginForm" (submit)="signIn($event)" >
  <div class="main-page-content-block-secondary"
       style="width: 400px; position: absolute; top: calc(50vh - 250px); left: calc(50vw - 200px);">

    <div class="row" *ngIf="bussinessLogo!=null">
      <div class="col mt-1 mb-3 text-center">
        <img [src]="bussinessLogo | secure | async"
             class="bussines-logo"
             alt="{{bussinessName}}" />
      </div>
    </div>
    <div class="row" *ngIf="bussinessLogo==null && bussinessName">
      <div class="col mb-3">
        <h1 class="text-center">
          <span>{{bussinessName}}</span>
        </h1>

      </div>
    </div>
    <div class="row" *ngIf="bussinessLogo==null && bussinessName==null">
      <div class="col mt-1 mb-3 text-center">
        <img src="../../../assets/progress2.gif"
             style="max-height: 120px;"
             alt="Loading" />
      </div>
    </div>
    <div class="row" *ngIf="checkingCredential">
      <div class="col mb-3 mt-3 text-center ">
          <span>Validating current credentials. Please wait</span><br />
          <img src="../../../assets/progress2.gif"
               style="max-height: 25px;"
               alt="Loading" />
      </div>
    </div>
    <div class="row" *ngIf="!checkingCredential">
      <div class="col mb-3">
        <label for="Username">Enter user name</label>
        <input class="form-control" name="username" formControlName="username" autocomplete="username" placeholder="username"
               required="required" />
      </div>
    </div>
    <div class="row" *ngIf="!checkingCredential">
      <div class="col mb-3">
        <label for="Password">Enter password</label>
        <input class="form-control" type="password" name="password" formControlName="password"
               placeholder="password" autocomplete="current-password" required="required" />
      </div>
    </div>
    <div class="row m-2" *ngIf="authFailedError">
      <label style="color: red"> {{authFailedError}}</label>
    </div>
    <div class="row" *ngIf="!checkingCredential">
      <div class="col text-center">
        <button class="btn btn-lg btn-primary" type="submit" id="btnLogin"  [class.button-loading]="loading">Log In</button>
      </div>
    </div>
  </div>
</form>
