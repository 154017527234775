import { Component, input, Input, OnInit } from '@angular/core';
import {
  PatientPlaylistModel,
  PatientProgramModel,
  PatientTargetModel,
} from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';
import { DialogHelperService } from '../../dialog.helper.service';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrl: '../../supervisor-main.component.css',
})
export class PlaylistComponent implements OnInit {
  patientId = input<number | undefined>();
  @Input() playlist: PatientPlaylistModel | null = null;

  isCollapsed = true;
  isLoading = false;

  patientPrograms: PatientProgramModel[] = [];
  patientTargets: PatientTargetModel[] = [];

  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    private dialogHelperService: DialogHelperService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.isLoading = true;

    this.supervisorApiService
      .GetPatientPrograms({ patientID: this.patientId() })
      .subscribe(
        (patientPrograms) => {
          console.log(patientPrograms);
          this.patientPrograms = patientPrograms;
          this.supervisorApiService
            .GetPatientTargets({ patientID: this.patientId() })
            .subscribe(
              (targets) => {
                this.patientTargets = targets;
                this.isLoading = false;
              },
              (err) => {
                this.isLoading = false;
                console.error(err);
                this.alertService.error(Utils.getAjaxError(err));
              }
            );
        },
        (error) => {
          console.error(error);
          this.alertService.error(Utils.getAjaxError(error));
          this.isLoading = false;
        }
      );
  }

  programForID(programID: number) {
    return this.patientPrograms.find((p) => p.ID === programID);
  }

  targetForID(targetID: number) {
    return this.patientTargets.find((t) => t.ID === targetID);
  }

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  get playlistNameDisplayName() {
    return this.playlist?.PlaylistName || 'No Name';
  }

  get playlistItemsNames() {
    // if (this.playlist == null) {
    return 'none';
    // } else {
    //   return this.playlist?.Items?.map(item => {
    //     if (item.PatientProgramID && item.program.name) {
    //       return item.program.name
    //     } else if (item.target && item.target.TargetName) {
    //       return item.target.TargetName
    //     } else {
    //       return 'No Name'
    //     }
    //   }).join(', ')
    // }
  }

  editPlaylist() {
    console.log('editPlaylist clicked!');
  }

  deletePlaylist() {
    console.log('deletePlaylist clicked!');
    if (this.playlist?.ID != null) {
      this.supervisorApiService
        .DeletePatientPlaylist({ patientPlaylistID: this.playlist.ID })
        .subscribe(
          () => {
            this.alertService.success('Playlist deleted successfully');
            this.dialogHelperService.onCloseAddPlaylist();
          },
          (error) => {
            console.error(error);
            this.alertService.error(Utils.getAjaxError(error));
          }
        );
    }
  }
}
