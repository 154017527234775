@if (isCollapsed) {
    <div class="container" (click)="toggleCollapsed()">
        <div class="row h-4rem align-items-center">
            <div class="col-3">
                <button type="button" class="btn btn-primary position-relative program-name">
                    {{ target?.TargetName }}
                  </button>
            </div>
            <div class="col-1">
                <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6;"></i>
            </div>
            <div class="col-3">
                <span class="fs-8 fw-bolder" style="color: #719eb6;"> Included playlists: </span>
                <span class="fs-8 " style="color: #719eb6;"> {{ targetIncludedPlaylistNames }} </span>
            </div>
            <div class="col-3">
                <span class="fs-8 fw-bolder" style="color: #719eb6;"> Skill Areas: </span>
                <span class="fs-8 " style="color: #719eb6;"> {{ targetSkillAreasNames }} </span>
            </div>
        </div>
    </div>
} @else {
    <div class="container">
        <div class="row h-4rem align-items-center">
            <div class="col-3">
                <button type="button" class="btn btn-primary position-relative program-name" (click)="toggleCollapsed()">
                    {{ target?.TargetName }}
                  </button>
            </div>
            <div class="col-1">
                <i class="pi pi-arrow-up" style="font-size: 1rem; color: #719eb6;"></i>
            </div>
            <div class="col-3">
                <span class="fs-8 fw-bolder" style="color: #719eb6;"> Included playlists: </span>
                <span class="fs-8 " style="color: #719eb6;"> {{ targetIncludedPlaylistNames }} </span>
            </div>
            <div class="col-3">
                <span class="fs-8 fw-bolder" style="color: #719eb6;"> Skill Areas: </span>
                <span class="fs-8 " style="color: #719eb6;"> {{ targetSkillAreasNames }} </span>
            </div>
            <div class="col-3">
                <div class="side-menu-buttons">
                    <button (click)="editTarget()" class="side-menu-button2">Edit Target</button>
                    <button (click)="deleteTarget()" class="switch-mode-button">Delete Target</button>
                </div>
            </div>
        </div>
        <div class="row">
            <p>TODO: Target Content.</p>
        </div>
    </div>
}
