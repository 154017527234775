import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';

import { PatientModel, PatientProgramModel, PatientTargetModel, TargetFailOption, TargetType } from '../../../core/api/models';
import { Utils } from '../../../core/utils';
import { AlertService } from '../../../shared/services/alert.service';
import { SupervisorApiService } from '../../../core/api/services';
import { DialogHelperService } from '../../dialog.helper.service';

@Component({
  selector: 'app-add-target',
  templateUrl: './add-target.component.html',
  styleUrl: './add-target.component.css'
})
export class AddTargetComponent implements OnInit {
  @Input() selectedPatient: PatientModel | null = null;
  @Output() close = new EventEmitter<void>();

  loadingPrograms: boolean = false;
  programs: any[] | undefined;
  selectedProgram: PatientProgramModel | null = null;

  targetTypes: any[] = [
    { value: TargetType.Anecdotal, text: 'Anecdotal' },
    { value: TargetType.DiscreteTrialTeaching, text: 'Discrete Trial Teaching' },
    { value: TargetType.ConditionalDiscrimination, text: 'Conditional Discrimination' },
    { value: TargetType.Duration, text: 'Duration' },
    { value: TargetType.Echoic, text: 'Echoic' },
    { value: TargetType.FluencyRate, text: 'Fluency Rate' },
    { value: TargetType.Frequency, text: 'Frequency' },
    { value: TargetType.GroupedFrequency, text: 'Grouped Frequency' },
    { value: TargetType.JumpTo, text: 'Jump To' },
    { value: TargetType.Quantity, text: 'Quantity' },
    { value: TargetType.TaskAnalysis, text: 'Task Analysis' },
    { value: TargetType.Toileting, text: 'Toileting' },
    { value: TargetType.WholePartialInterval, text: 'Whole Partial Interval' },
  ]
    
  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    private dialogHelperService: DialogHelperService,
  ) {}

  // TODO: Add a method to get the BSP/BIP to fill in the goalName

  targetForm = new FormGroup({
    targetName: new FormControl<string | null>(null, { validators: [Validators.required, Validators.maxLength(50)] }),
    goalName: new FormControl<string | null>(null, { validators: [Validators.required, Validators.maxLength(50)] }),
    discriminativeStimulus: new FormControl<string | null>(null, { validators: [Validators.required, Validators.maxLength(200)] }),
    desiredTrials: new FormControl<number | null>(1, { validators: [Validators.required, Validators.min(1), Validators.max(100)] }),
    targetType: new FormControl<TargetType>(TargetType.Anecdotal, { validators: [Validators.required] }),
    patientProgramID: new FormControl<number | null>(null),
    instructions: new FormControl<string | null>(null),
    isFavorite: new FormControl<boolean | null>(false),
  });

  currentTarget?: PatientTargetModel;

  titleName: string = 'Add Target';
  actionName: string = 'Add';

  ngOnInit(): void {
    this.currentTarget = {
      PatientID: this.selectedPatient?.ID,
      PromptOrderID: 1,
      TargetFailOption: TargetFailOption.MarkAsFailed,
    };

    this.loadingPrograms = true;
    this.supervisorApiService.GetPatientPrograms({ patientID: this.selectedPatient?.ID })
    .subscribe(
      (programs) => {
        this.loadingPrograms = false;
        this.programs = programs;

        // EDIT LOGIC
        if (this.dialogHelperService.targetToEdit() != null) {
          this.titleName = 'Edit Target';
          this.actionName = 'Save';
          this.currentTarget = this.dialogHelperService.targetToEdit()!;

          this.targetForm.patchValue({
            targetName: this.currentTarget.TargetName,
            goalName: this.currentTarget.GoalName,
            discriminativeStimulus: this.currentTarget.DiscriminativeStimulus,
            desiredTrials: this.currentTarget.DesiredTrials,
            targetType: this.currentTarget.TargetType,
            patientProgramID: this.currentTarget.PatientProgramID,
            instructions: this.currentTarget.Instructions,
            isFavorite: this.currentTarget.IsFavorite,
          });
        }

      },
      (err) => {
        this.loadingPrograms = false;
         this.alertService.error(Utils.getAjaxError(err));
      });
  }

  onCancel() {
    this.currentTarget = undefined;
    if (this.targetForm)
      this.targetForm.reset();
    this.close.emit();
  }

  onSubmit() {
    this.targetForm.markAllAsTouched();

    // TEMP CODE FOR ADDING PROMT
    this.supervisorApiService.SavePromptOrder({ body: {
        PromptOrderName: "Default",
        IsBackwards: false,
        Options: [
          {
            "Text": "Full Physical",
            "Value": "Full Physical",
            "Color": "#C15757",
            "PromptOrderItemType": 2
          },
          {
            "Text": "Partial Physical",
            "Value": "Partial Physical",
            "Color": "#C15757",
            "PromptOrderItemType": 2
          },
          {
            "Text": "Model",
            "Value": "Model",
            "Color": "#CC9E7F",
            "PromptOrderItemType": 0
          },
          {
            "Text": "Gesture",
            "Value": "Gesture",
            "Color": "#8AC379",
            "PromptOrderItemType": 1
          },
          {
            "Text": "Independent",
            "Value": "Independent",
            "Color": "#8AC379",
            "PromptOrderItemType": 1
          },
        ]
      }
    })
    .subscribe(
      (savedItem) => {
        console.log('Prompt order saved successfully');
      },
      (err) => {
        console.error(Utils.getAjaxError(err));
        //this.alertService.error(Utils.getAjaxError(err));
      });

    if (this.targetForm.invalid) {
      const errors = Utils.getFormErrors(this.targetForm);
      if (errors.length > 0) {
        this.alertService.error(errors.join(' \n'));
        return;
      }
    }

    var formValue = this.targetForm.getRawValue();
    console.log(formValue);

    this.currentTarget =
    {
      ...this.currentTarget,
      ...formValue
    }
    console.info(this.currentTarget);

    this.supervisorApiService.SavePatientTarget({ body: this.currentTarget })
    .subscribe(
      (savedItem) => {
        this.currentTarget = undefined;
        this.targetForm.reset();
        this.alertService.success('Target saved successfully');
        this.close.emit();
      },
      (err) => {
         this.alertService.error(Utils.getAjaxError(err));
         this.close.emit();
      }
    );
  }
}
