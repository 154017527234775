import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-box-quantity',
  templateUrl: './box-quantity.component.html',
})
export class BoxQuantityComponent implements AfterViewInit {
  @Input() text: string|undefined = '-';
  @Input() quantity: number | null = null;
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  @Input() expectedAmount: number = 0;
  @Input() unit: string = '';
  @Input() quantities: (number | null)[] = [];

  private longPressTimeout: any;
  extraOpened: boolean = false;

  extraBoxes: ElementRef[] = [];

  @ViewChild('durationBox', { static: true }) durationBox!: ElementRef;
  @ViewChild('durationExtraBox', { static: true }) durationExtraBox!: ElementRef;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    // Initialize the position of the duration-extra-container
    const durationContainer = this.durationBox.nativeElement;
    const extraContainer = this.durationExtraBox.nativeElement;
    const body = document.querySelector('body') as HTMLElement;

    if (durationContainer && extraContainer) {
      // Append the extra container to the duration container
      body.appendChild(extraContainer);
      this.updateExtraContainerPosition(durationContainer, extraContainer);

    }

    // Update position on window resize
    window.addEventListener('resize', () => {
      this.updateExtraContainerPosition(durationContainer, extraContainer);
    });
    // Update position on scroll
    window.addEventListener('scroll', () => {
      this.updateExtraContainerPosition(durationContainer, extraContainer);
    });
    const scrollableElements = document.querySelectorAll('div');
    scrollableElements.forEach(element => {
      element.addEventListener('scroll', () => {
        this.updateExtraContainerPosition(durationContainer, extraContainer);
      });
    });

    // Observe changes in the DOM that might affect positioning
    const observer = new MutationObserver(() => {
      this.updateExtraContainerPosition(durationContainer, extraContainer);
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true
    });
    this.extraBoxes = [this.durationExtraBox];
    this.cdRef.detectChanges();
  }

  onClickOutside() {
    this.extraOpened = false;
  }

  private updateExtraContainerPosition(durationContainer: HTMLElement, extraContainer: HTMLElement): void {
    const top = durationContainer.offsetTop;
    const rect = durationContainer.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const containerRight = rect.right;
    const spaceOnRight = windowWidth - containerRight;
    const spaceOnLeft = rect.left;
    const extraContainerWidth = 180; // Width of the extra container

    if (spaceOnRight < extraContainerWidth + 50 && spaceOnLeft >= extraContainerWidth + 50) {
      // Position to the left if there's not enough space on the right
      extraContainer.style.left = `${rect.left - extraContainerWidth - 10}px`;
    } else {
      // Default positioning to the right
      extraContainer.style.left = `${rect.left + rect.width + 10}px`;
    }

    extraContainer.style.top = `${top}px`;
  }

  startLongPress(): void {
    this.longPressTimeout = setTimeout(() => {
      this.showAllTimes();
    }, 400);
  }

  showAllTimes(): void {
    // Implement the logic for showing all times here
    console.log('Showing all times');
    this.extraOpened = !this.extraOpened;
    if (this.extraOpened) {
      // Hide all other extra containers
      const allExtraContainers = document.querySelectorAll('.duration-extra-container');
      allExtraContainers.forEach((container: Element) => {
        if (container instanceof HTMLElement && container !== this.durationExtraBox.nativeElement) {
          container.classList.add('hide');
        }
      });

      // Ensure the current extra container is visible
      this.durationExtraBox.nativeElement.classList.remove('hide');

      // Update the position of the extra container
      setTimeout(() => {
        const durationContainer = this.durationBox.nativeElement;
        const extraContainer = this.durationExtraBox.nativeElement;
        this.updateExtraContainerPosition(durationContainer, extraContainer);
      });
    }
  }

  endLongPress(): void {
    clearTimeout(this.longPressTimeout);
  }


  closeExtra() {
    this.extraOpened = false;
  }

  hitQuantity(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.quantity) {
      this.quantities.push(this.quantity);
      this.quantity=null;
    }
  }
}