import { PatientTargetStatus } from "../../core/api/models";

export function getPatientTargetStatusLabel(targetStatus: PatientTargetStatus) {
  switch (targetStatus) {
    case PatientTargetStatus.Aquisition:
      return 'Acquisition';
    case PatientTargetStatus.Failed:
      return 'Failed';
    case PatientTargetStatus.Waiting:
      return 'Waiting';
    case PatientTargetStatus.Probe:
      return 'Probe';
    case PatientTargetStatus.Mastered:
      return 'Mastered';
  }
  return 'Not Defined';
}

export function getPatientTargetStatusColor(targetStatus: PatientTargetStatus) {
    switch (targetStatus) {
        case PatientTargetStatus.Mastered:
            return '#8AC379';
        case PatientTargetStatus.Failed:
            return '#C15757';
        case PatientTargetStatus.Waiting:
            return '#A7B8D6';
        case PatientTargetStatus.Probe:
            return '#DFE2AF';
        case PatientTargetStatus.Aquisition:
            return '#CC9E7F';
    }
    return '#FFFFFF';
}