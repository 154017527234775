import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  PatientModel,
  PatientProgramModel,
  PatientTargetModel,
  SkillAreaModel,
} from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { DialogHelperService } from '../../dialog.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';

@Component({
  selector: 'app-add-program',
  templateUrl: './add-program.component.html',
  styleUrl: './add-program.component.css',
})
export class AddProgramComponent implements OnInit {
  @Input() selectedPatient: PatientModel | null = null;
  programToEdit: PatientProgramModel | null = null;

  @Output() close = new EventEmitter<void>();

  loadingTargets: boolean = false;
  targets: PatientTargetModel[] | undefined;
  selectedTargets: PatientTargetModel[] | undefined;

  loadingSkillAreas: boolean = false;
  skillAreas: SkillAreaModel[] | undefined;
  selectedSkillArea: SkillAreaModel | undefined;

  loading: boolean = false;

  titleName: string = 'Add Program';
  actionName: string = 'Add';

  programId: number = 0;
  enteredName = '';
  enteredDetails =
    'In six months, Client will increase his flexibility when completing age-appropriate daily tasks by gaining 8 skills to increase his ability to complete daily multi-step routines independently, void of maladaptive behaviors, with 90% independence across five consecutive days, based on data collected by behavior technicians in home, clinical, telehealth, and community settings.';

  constructor(
    private supervisorApiService: SupervisorApiService,
    private dialogHelperService: DialogHelperService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.loadingTargets = true;
    this.supervisorApiService
      .GetPatientTargets({
        patientID: this.selectedPatient?.ID,
      })
      .subscribe(
        (targets) => {
          this.targets = targets;
          this.loadingTargets = false;

          this.loadingSkillAreas = true;
          this.supervisorApiService.GetSkillAreas().subscribe(
            (skillAreas) => {
              this.skillAreas = skillAreas;
              this.loadingSkillAreas = false;

              // EDIT LOGIC
              if (this.dialogHelperService.programToEdit() != null) {
                this.titleName = 'Edit Program';
                this.actionName = 'Save';
                this.programToEdit = this.dialogHelperService.programToEdit();
                this.programId = this.programToEdit?.ID ?? 0;
                this.enteredName = this.programToEdit?.ProgramName ?? '';
                this.enteredDetails = this.programToEdit?.Details ?? '';
                this.selectedTargets = this.targets?.filter(
                  (target) =>
                    this.programToEdit?.Targets?.findIndex(
                      (t) => t.ID === target.ID
                    ) !== -1
                );
                this.selectedSkillArea = this.skillAreas?.find(
                  (skillArea) =>
                    skillArea.ID === this.programToEdit?.SkillAreaID
                );
              }
            },
            (error) => {
              console.error(error);
              this.loadingSkillAreas = false;
            }
          );
        },
        (error) => {
          console.error(error);
          this.loadingTargets = false;
        }
      );
  }

  onCancel() {
    this.close.emit();
  }

  onSubmit() {
    this.loading = true;
    this.supervisorApiService
      .SavePatientProgram({
        body: {
          ID: this.programId,
          Details: this.enteredDetails,
          PatientID: this.selectedPatient?.ID,
          ProgramName: this.enteredName,
          SkillAreaID: this.selectedSkillArea?.ID,
          SkillAreaName: null,
          Targets: this.selectedTargets,
        },
      })
      .subscribe(
        (patientProgram) => {
          console.log(patientProgram);
          this.loading = false;
          this.alertService.success('Program saved successfully');
          this.close.emit();
        },
        (error) => {
          console.error(error);
          this.alertService.error(Utils.getAjaxError(error));
          this.loading = false;
          this.close.emit();
        }
      );
  }
}
