<div class="dashboard">
  <div class="header">
    <div class="client-name">{{this.patient?.FirstName}} {{this.patient?.LastName}}</div>
    <button (click)="switchToSupervisor()" class="updates-button">Switch to Supervisor</button>
    <button class="updates-button">Updates</button>
  </div>
  <div class="flags-container">
    <div class="flags">
      <div class="top-box">
        <div class="flag flex-column">
          <div *ngIf="!isStarted()">Session
            <button class="button-start" (click)="startSession()">Start</button>
          </div>
          <div *ngIf="isStarted()">{{timerString()}}
            <button class="button-end" (click)="endSession()">End</button>
          </div>
        </div>
      </div>

      <div class="flags-horizontal">
        @for (target of favoriteTargets; track target.ID) {
          @if (isDurationTarget(target)) {
            <app-box-duration  [text]="target.TargetName?.toString()"></app-box-duration>
          }
          @if (isFrequencyTarget(target)) {
            <app-box-frequency  [dayValue]="0" [showDayValue]="false" [targetID]="target.ID??0" [text]="target.TargetName?.toString()"></app-box-frequency>
          }
          @if (isDttTarget(target)) {
            <app-box-dtt  [text]="target.TargetName?.toString()" [requiredTrials]="target.DesiredTrials??1" [selectedOptions]="[]"></app-box-dtt>
          }
          @if (isEchoicTarget(target)) {
            <app-box-echoic  [text]="target.TargetName?.toString()" [requiredTrials]="target.DesiredTrials??1" [selectedOptions]="[]"></app-box-echoic>
          }
          @if (isFluencyRateTarget(target)) {
            <app-box-fluencyrate  [text]="target.TargetName?.toString()" [expectedAmount]="target.DesiredTrials??1"
              [timerInitialValue]="target.Duration??60"></app-box-fluencyrate>
          }
          @if (isQuantityTarget(target)) {
            <app-box-quantity [text]="target.TargetName?.toString()" [expectedAmount]="target.DesiredTrials??1" [unit]="target.QuantityUnit??'units'"></app-box-quantity>
          }
          @if (isAnecdotalTarget(target)) {
            <app-box-anecdotal  [text]="target.TargetName?.toString()" [expectedAmount]="target.DesiredTrials??1"></app-box-anecdotal>
          }
        } 
        <!-- <app-box-frequency text="Flagged Big Text 1"></app-box-frequency>
        <app-box-frequency text="Flagged 2"></app-box-frequency>
        <app-box-frequency text="Flagged 3"></app-box-frequency>
        <app-box-frequency text="Flagged 4"></app-box-frequency>
        <app-box-frequency text="Flagged 5"></app-box-frequency>
        <app-box-frequency text="Flagged 6"></app-box-frequency>
        <app-box-duration text="Time something"></app-box-duration>
        <app-box-frequency [dayValue]="2" text="Count something"></app-box-frequency>
        <app-box-dtt text="/ah/" [requiredTrials]="3" [selectedOptions]="[]"></app-box-dtt>
        <app-box-dtt text="/s/" [requiredTrials]="3" [selectedOptions]="[]"
          [currentLevel]="{name: 'Acquisition', color: '#a87553'}"></app-box-dtt>
        <app-box-dtt text="/m/" [requiredTrials]="3" [selectedOptions]="[]"
          [currentLevel]="{name: 'Mastered', color: '#6f9c61'}"></app-box-dtt>
        <app-box-quantity text="Distance" [expectedAmount]="6" [unit]="'steps'"></app-box-quantity>
        <app-box-quantity text="Test Score" [expectedAmount]="2" [unit]="'%'"></app-box-quantity>
        <app-box-quantity text="Some really big text stuff" [expectedAmount]="2"
          [unit]="'Some really big unit'"></app-box-quantity>
        <app-box-fluencyrate text="WPM" [expectedAmount]="2" [timerInitialValue]="60"></app-box-fluencyrate>
        <app-box-fluencyrate text="10s exmaple" [expectedAmount]="2" [timerInitialValue]="10"></app-box-fluencyrate>
        <app-box-anecdotal text="Anecdotal" [expectedAmount]="2"></app-box-anecdotal>
        <app-box-echoic text="Echoic" [requiredTrials]="3" [selectedOptions]="[]"></app-box-echoic> -->

      </div>
    </div>
  </div>

  <div class="flags">

    <app-box-duration text="Paralell Play"></app-box-duration>
    <app-box-duration text="Other timer"></app-box-duration>
    <app-box-frequency text="Out of Seat"></app-box-frequency>
    <div class="flags-horizontal">

      <app-box-frequency text="Swearing" [showDayValue]="false"></app-box-frequency>

      <app-box-frequency text=" Self Injury" [showDayValue]="false"></app-box-frequency>

      <app-box-frequency text="Elopement" [showDayValue]="false"></app-box-frequency>

      <app-box-frequency text="Swearing" [showDayValue]="false"></app-box-frequency>

      <app-box-frequency text=" Self Injury" [showDayValue]="false"></app-box-frequency>

      <app-box-frequency text="Elopement" [showDayValue]="false"></app-box-frequency>

    </div>
  </div>
  <div class="programs">
    @for (itemArea of getShowingItemsGroupedByTitle(); track itemArea.Title){
      
    <div class="program-title">{{itemArea.Title}}</div>
    @for (item of itemArea.items; track $index)
    {
    <div class="program-name">{{item.Name}}</div>
    <div class="program-items">
      @for (target of item.Targets; track target.ID) {
        @if (isDurationTarget(target)) {
          <app-box-duration [isBottomBox]="true" [text]="target.TargetName?.toString()"></app-box-duration>
        }
        @if (isFrequencyTarget(target)) {
          <app-box-frequency [isBottomBox]="true" [dayValue]="0" [showDayValue]="false" [targetID]="target.ID??0" [text]="target.TargetName?.toString()"></app-box-frequency>
        }
        @if (isDttTarget(target)) {
          <app-box-dtt [isBottomBox]="true" [text]="target.TargetName?.toString()" [requiredTrials]="target.DesiredTrials??1" [selectedOptions]="[]"></app-box-dtt>
        }
        @if (isEchoicTarget(target)) {
          <app-box-echoic [isBottomBox]="true" [text]="target.TargetName?.toString()" [requiredTrials]="target.DesiredTrials??1" [selectedOptions]="[]"></app-box-echoic>
        }
        @if (isFluencyRateTarget(target)) {
          <app-box-fluencyrate [isBottomBox]="true" [text]="target.TargetName?.toString()" [expectedAmount]="target.DesiredTrials??1"
            [timerInitialValue]="target.Duration??60"></app-box-fluencyrate>
        }
        @if (isQuantityTarget(target)) {
          <app-box-quantity [isBottomBox]="true" [text]="target.TargetName?.toString()" [expectedAmount]="target.DesiredTrials??1" [unit]="target.QuantityUnit??'units'"></app-box-quantity>
        }
        @if (isAnecdotalTarget(target)) {
          <app-box-anecdotal [isBottomBox]="true" [text]="target.TargetName?.toString()" [expectedAmount]="target.DesiredTrials??1"></app-box-anecdotal>
        }
      } 
    </div>
    }
    }
    <!-- <div class="program-title">Communication</div>
    <div class="program-name">Echoics</div>
    <div class="program-items">
      <app-box-duration [isBottomBox]="true" text="Time something"></app-box-duration>
      <app-box-frequency [isBottomBox]="true" [dayValue]="2" text="Count something"></app-box-frequency>
      <app-box-dtt [isBottomBox]="true" text="/ah/" [requiredTrials]="3" [selectedOptions]="[]"></app-box-dtt>
      <app-box-dtt [isBottomBox]="true" text="/s/" [requiredTrials]="3" [selectedOptions]="[]"
        [currentLevel]="{name: 'Acquisition', color: '#a87553'}"></app-box-dtt>
      <app-box-dtt [isBottomBox]="true" text="/m/" [requiredTrials]="3" [selectedOptions]="[]"
        [currentLevel]="{name: 'Mastered', color: '#6f9c61'}"></app-box-dtt>
      <app-box-quantity [isBottomBox]="true" text="Distance" [expectedAmount]="6" [unit]="'steps'"></app-box-quantity>
      <app-box-quantity [isBottomBox]="true" text="Test Score" [expectedAmount]="2" [unit]="'%'"></app-box-quantity>
      <app-box-quantity [isBottomBox]="true" text="Some really big text stuff" [expectedAmount]="2"
        [unit]="'Some really big unit'"></app-box-quantity>
      <app-box-fluencyrate [isBottomBox]="true" text="WPM" [expectedAmount]="2"
        [timerInitialValue]="60"></app-box-fluencyrate>
      <app-box-fluencyrate [isBottomBox]="true" text="10s exmaple" [expectedAmount]="2"
        [timerInitialValue]="10"></app-box-fluencyrate>
      <app-box-anecdotal [isBottomBox]="true" text="Anecdotal" [expectedAmount]="2"></app-box-anecdotal>
      <app-box-echoic [isBottomBox]="true" text="Echoic" [requiredTrials]="3" [selectedOptions]="[]"></app-box-echoic>
    </div> -->
  </div>
  @for (reminder of showingReminders; track reminder.ID) {
  <div class="alert" (click)="closeReminder(reminder)">{{reminder.Message}}</div>
  }
  <div class="program-notes">Program Notes:

    <textarea class="program-notes-textarea"></textarea>
  </div>
  <div class="programs-list-container">

    <div class="programs-list">
      @for (program of programs; track program.ID) {
      <div class="program-list-item" (click)="selectProgram(program)">
        <span class="badge-count"
          [class.badge-count-green]="countMasteredTargets(program) >= (program.Targets?.length ?? 0)"
          [class.badge-count-red]="countMasteredTargets(program) < (program.Targets?.length ?? 0)">{{countMasteredTargets(program)}}/{{program.Targets?.length??0}}</span>
        <div class="program-list-item-title">Program</div>
        <div class="program-list-item-name">{{program.ProgramName}}</div>
        <div class="program-list-item-circles">
          @for (counter of program.Counters; track counter.targetStatus) {
          <div class="program-list-item-circle" [style.background-color]="counter.color">{{counter.count}}</div>
          }
        </div>
      </div>
      }
      @for (playlist of playlists; track playlist.ID) {
        <div class="program-list-item" (click)="selectPlaylist(playlist)">
          <!-- <span class="badge-count"
            [class.badge-count-green]="countMasteredTargets(playlist) >= getTotalTargets(playlist)"
            [class.badge-count-red]="countMasteredTargets(playlist) < getTotalTargets(playlist)">{{countMasteredTargets(playlist)}}/{{getTotalTargets(playlist)}}</span> -->
          <div class="program-list-item-title">Playlist</div>
          <div class="program-list-item-name">{{playlist.PlaylistName}}</div>
          <div class="program-list-item-circles">
            <!-- @for (item of playlist.Items; track item.ID) {
            <div class="program-list-item-circle" [style.background-color]="counter.color">{{counter.count}}</div>
            } -->
          </div>
        </div>
        }
        
      @for (target of targets; track target.ID) {
        <div class="program-list-item" (click)="selectTarget(target)">
          <!-- <span class="badge-count"
            [class.badge-count-green]="countMasteredTargets(playlist) >= getTotalTargets(playlist)"
            [class.badge-count-red]="countMasteredTargets(playlist) < getTotalTargets(playlist)">{{countMasteredTargets(playlist)}}/{{getTotalTargets(playlist)}}</span> -->
          <div class="program-list-item-title">Target</div>
          <div class="program-list-item-name">{{target.TargetName}}</div>
          <div class="program-list-item-circles">
            <!-- @for (item of playlist.Items; track item.ID) {
            <div class="program-list-item-circle" [style.background-color]="counter.color">{{counter.count}}</div>
            } -->
          </div>
        </div>
        }

      

    </div>
    <div class="programs-list-buttons">
      <button class="programs-list-button">Filter</button>
      <button class="programs-list-button" (click)="nextOnList()">Next</button>
    </div>
  </div>