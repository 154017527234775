<div class="horizontal-flex-list">
    <div class="form-check">
        <input class="form-check-input checkmarks-check" type="checkbox" value="" id="inactive" >
        <label class="form-check-label checkmarks-label" for="inactive">
            Show Inactive
        </label>
    </div>
    <div class="form-check">
        <input class="form-check-input checkmarks-check" type="checkbox" value="" id="mastered" >
        <label class="form-check-label checkmarks-label" for="mastered">
            Show Mastered
        </label>
    </div>
    <div class="form-check">
        <input class="form-check-input checkmarks-check" type="checkbox" value="" id="future" >
        <label class="form-check-label checkmarks-label" for="future">
            Show Future Targets
        </label>
    </div>
</div>
<ul id="targets">
    @if (loadingTargets) {
        <app-loading-indicator />
    } @else {
        @for (target of targets; track target.TargetName) {
            <li>
                <app-target [target]="target" />
            </li>
        } @empty {
            <p style="text-align: center; margin-top: 20px; font-size: 1rem; color: #719eb6;">There are no targets yet.</p>
        }
    }
</ul>
