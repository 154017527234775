import { Component, computed, effect, input, OnInit, Signal, WritableSignal } from '@angular/core';
import { PatientPlaylistModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { DialogHelperService } from '../dialog.helper.service';

@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrl: '../supervisor-main.component.css'
})
export class PlaylistsComponent implements OnInit {

  patientId = input<number>();
  
  playlists: PatientPlaylistModel[] | undefined;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private dialogHelperService: DialogHelperService,
  ) {
    effect(() => {
      console.log('update signal triggered: ', this.dialogHelperService.updateSignal());
      this.loadData();
    });
  }

  ngOnInit(): void {
    // this.playlists = computed<Playlist[]>(() => {
    //   const patient = this.patientsService.patients().find(patient => patient.id === this.patientId());
    //   return this.playlistsService.playlists().filter(playlist => patient?.playlists?.includes(playlist.id));
    // });
  }

  loadData() {
    this.supervisorApiService.GetPatientPlaylists({ patientID: this.patientId() }).subscribe(
      (playlists) => {
        console.log(playlists);
        this.playlists = playlists;
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
