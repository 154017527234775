
  <h2> {{ titleName }} </h2>
  <div class="overlay" *ngIf="loading">
    <div class="spinner"></div>
  </div>
  <form (ngSubmit)="onSubmit()">
    <p>
      <label for="name">Name</label>
      <input type="text" id="name" name="name" [(ngModel)]="enteredName" />
    </p>

    <p>
      <label for="selectedProgram">Targets:</label>
      <p-multiSelect 
        [ngStyle]="{'width': '100%'}"
        [panelStyle]="{'width':'100%'}"
        [options]="targets" 
        name="selectedTargets"
        [(ngModel)]="selectedTargets"
        placeholder="Select Target"
        optionLabel="TargetName" 
        display="chip" 
        [showClear]="true" />
    </p>

    <p>
      <label for="selectedSkillArea">Targets:</label>
      <p-dropdown 
        [options]="skillAreas" 
        [(ngModel)]="selectedSkillArea"
        name="selectedSkillArea"
        [checkmark]="true" 
        optionLabel="SkillAreaName" 
        [showClear]="true" 
        placeholder="Select a Skill Area" />
    </p>

    <p>
      <label for="details">Details</label>
      <textarea
        id="details"
        rows="5"
        name="details"
        [(ngModel)]="enteredDetails"
      ></textarea>
    </p>

    <p class="actions">
      <button type="button" (click)="onCancel()">Cancel</button>
      <button type="submit">{{ actionName }}</button>
    </p>
  </form>