/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { GetMyPatients } from '../fn/staff/get-my-patients';
import { GetMyPatients$Params } from '../fn/staff/get-my-patients';
import { GetPatientBehaviorData } from '../fn/staff/get-patient-behavior-data';
import { GetPatientBehaviorData$Params } from '../fn/staff/get-patient-behavior-data';
import { GetPatientBehaviors } from '../fn/staff/get-patient-behaviors';
import { GetPatientBehaviors$Params } from '../fn/staff/get-patient-behaviors';
import { GetPatientInfo } from '../fn/staff/get-patient-info';
import { GetPatientInfo$Params } from '../fn/staff/get-patient-info';
import { GetPatientSessions } from '../fn/staff/get-patient-sessions';
import { GetPatientSessions$Params } from '../fn/staff/get-patient-sessions';
import { GetPatientTargetData } from '../fn/staff/get-patient-target-data';
import { GetPatientTargetData$Params } from '../fn/staff/get-patient-target-data';
import { PatientBehaviorDataModel } from '../models/patient-behavior-data-model';
import { PatientBehaviorModel } from '../models/patient-behavior-model';
import { PatientModel } from '../models/patient-model';
import { PatientSessionModel } from '../models/patient-session-model';
import { PatientTargetDataModel } from '../models/patient-target-data-model';
import { SavePatientBehavior } from '../fn/staff/save-patient-behavior';
import { SavePatientBehavior$Params } from '../fn/staff/save-patient-behavior';
import { SavePatientBehaviorData } from '../fn/staff/save-patient-behavior-data';
import { SavePatientBehaviorData$Params } from '../fn/staff/save-patient-behavior-data';
import { SavePatientSession } from '../fn/staff/save-patient-session';
import { SavePatientSession$Params } from '../fn/staff/save-patient-session';
import { SavePatientTargetData } from '../fn/staff/save-patient-target-data';
import { SavePatientTargetData$Params } from '../fn/staff/save-patient-target-data';

@Injectable({ providedIn: 'root' })
export class StaffApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiStaffGetMyPatientsGet()` */
  static readonly ApiStaffGetMyPatientsGetPath = '/api/Staff/GetMyPatients';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetMyPatients()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetMyPatients$Response(params?: GetMyPatients$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientModel>>> {
    return GetMyPatients(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetMyPatients$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetMyPatients(params?: GetMyPatients$Params, context?: HttpContext): Observable<Array<PatientModel>> {
    return this.GetMyPatients$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientModel>>): Array<PatientModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientInfoGet()` */
  static readonly ApiStaffGetPatientInfoGetPath = '/api/Staff/GetPatientInfo';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientInfo$Response(params?: GetPatientInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientModel>> {
    return GetPatientInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientInfo(params?: GetPatientInfo$Params, context?: HttpContext): Observable<PatientModel> {
    return this.GetPatientInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientModel>): PatientModel => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientSessionsGet()` */
  static readonly ApiStaffGetPatientSessionsGetPath = '/api/Staff/GetPatientSessions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientSessions()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientSessions$Response(params?: GetPatientSessions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientSessionModel>>> {
    return GetPatientSessions(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientSessions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientSessions(params?: GetPatientSessions$Params, context?: HttpContext): Observable<Array<PatientSessionModel>> {
    return this.GetPatientSessions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientSessionModel>>): Array<PatientSessionModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffSavePatientSessionPost()` */
  static readonly ApiStaffSavePatientSessionPostPath = '/api/Staff/SavePatientSession';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientSession()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientSession$Response(params?: SavePatientSession$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientSessionModel>> {
    return SavePatientSession(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientSession$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientSession(params?: SavePatientSession$Params, context?: HttpContext): Observable<PatientSessionModel> {
    return this.SavePatientSession$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientSessionModel>): PatientSessionModel => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientBehaviorsGet()` */
  static readonly ApiStaffGetPatientBehaviorsGetPath = '/api/Staff/GetPatientBehaviors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientBehaviors()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviors$Response(params?: GetPatientBehaviors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientBehaviorModel>>> {
    return GetPatientBehaviors(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientBehaviors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviors(params?: GetPatientBehaviors$Params, context?: HttpContext): Observable<Array<PatientBehaviorModel>> {
    return this.GetPatientBehaviors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientBehaviorModel>>): Array<PatientBehaviorModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffSavePatientBehaviorPost()` */
  static readonly ApiStaffSavePatientBehaviorPostPath = '/api/Staff/SavePatientBehavior';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientBehavior()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientBehavior$Response(params?: SavePatientBehavior$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientBehaviorModel>> {
    return SavePatientBehavior(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientBehavior$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientBehavior(params?: SavePatientBehavior$Params, context?: HttpContext): Observable<PatientBehaviorModel> {
    return this.SavePatientBehavior$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientBehaviorModel>): PatientBehaviorModel => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientTargetDataGet()` */
  static readonly ApiStaffGetPatientTargetDataGetPath = '/api/Staff/GetPatientTargetData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientTargetData()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientTargetData$Response(params?: GetPatientTargetData$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientTargetDataModel>>> {
    return GetPatientTargetData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientTargetData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientTargetData(params?: GetPatientTargetData$Params, context?: HttpContext): Observable<Array<PatientTargetDataModel>> {
    return this.GetPatientTargetData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientTargetDataModel>>): Array<PatientTargetDataModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffSavePatientTargetDataPost()` */
  static readonly ApiStaffSavePatientTargetDataPostPath = '/api/Staff/SavePatientTargetData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientTargetData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientTargetData$Response(params?: SavePatientTargetData$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientTargetDataModel>> {
    return SavePatientTargetData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientTargetData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientTargetData(params?: SavePatientTargetData$Params, context?: HttpContext): Observable<PatientTargetDataModel> {
    return this.SavePatientTargetData$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientTargetDataModel>): PatientTargetDataModel => r.body)
    );
  }

  /** Path part for operation `apiStaffGetPatientBehaviorDataGet()` */
  static readonly ApiStaffGetPatientBehaviorDataGetPath = '/api/Staff/GetPatientBehaviorData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetPatientBehaviorData()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorData$Response(params?: GetPatientBehaviorData$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PatientBehaviorDataModel>>> {
    return GetPatientBehaviorData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetPatientBehaviorData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetPatientBehaviorData(params?: GetPatientBehaviorData$Params, context?: HttpContext): Observable<Array<PatientBehaviorDataModel>> {
    return this.GetPatientBehaviorData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PatientBehaviorDataModel>>): Array<PatientBehaviorDataModel> => r.body)
    );
  }

  /** Path part for operation `apiStaffSavePatientBehaviorDataPost()` */
  static readonly ApiStaffSavePatientBehaviorDataPostPath = '/api/Staff/SavePatientBehaviorData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `SavePatientBehaviorData()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientBehaviorData$Response(params?: SavePatientBehaviorData$Params, context?: HttpContext): Observable<StrictHttpResponse<PatientBehaviorDataModel>> {
    return SavePatientBehaviorData(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `SavePatientBehaviorData$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  SavePatientBehaviorData(params?: SavePatientBehaviorData$Params, context?: HttpContext): Observable<PatientBehaviorDataModel> {
    return this.SavePatientBehaviorData$Response(params, context).pipe(
      map((r: StrictHttpResponse<PatientBehaviorDataModel>): PatientBehaviorDataModel => r.body)
    );
  }

}
