import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-box-duration',
  templateUrl: './box-duration.component.html',
})
export class BoxDurationComponent implements AfterViewInit {
  @Input() text: string|undefined = '-';
  @Input() amountOfTimes: number = 0;
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  private longPressTimeout: any;
  isStarted: boolean = false;
  timerValue: number = 0;
  private startTime: number = 0;
  times: string[] = [
  ];
  extraOpened: boolean = false;
  extraBoxes: ElementRef[] = [];
  @ViewChild('durationBox', { static: true }) durationBox!: ElementRef;
  @ViewChild('durationExtraBox', { static: true }) durationExtraBox!: ElementRef;

  constructor(private cdRef: ChangeDetectorRef) {}
  ngAfterViewInit() {
    // Initialize the position of the duration-extra-container
    const durationContainer = this.durationBox.nativeElement;
    const extraContainer = this.durationExtraBox.nativeElement;
    const body = document.querySelector('body') as HTMLElement;

    if (durationContainer && extraContainer) {
      // Append the extra container to the duration container
      body.appendChild(extraContainer);
      this.updateExtraContainerPosition(durationContainer, extraContainer);

    }

    // Update position on window resize
    window.addEventListener('resize', () => {
      this.updateExtraContainerPosition(durationContainer, extraContainer);
    });
    // Update position on scroll
    window.addEventListener('scroll', () => {
      this.updateExtraContainerPosition(durationContainer, extraContainer);
    });
    const scrollableElements = document.querySelectorAll('div');
    scrollableElements.forEach(element => {
      element.addEventListener('scroll', () => {
        this.updateExtraContainerPosition(durationContainer, extraContainer);
      });
    });

    // Observe changes in the DOM that might affect positioning
    const observer = new MutationObserver(() => {
      this.updateExtraContainerPosition(durationContainer, extraContainer);
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true
    });
  
    this.extraBoxes = [this.durationExtraBox];
    this.cdRef.detectChanges();
  }


  
  onClickOutside() {
    if (this.extraOpened) {
      this.durationExtraBox.nativeElement.classList.add('hide');
      this.extraOpened = false;
    }
  }

  private updateExtraContainerPosition(durationContainer: HTMLElement, extraContainer: HTMLElement): void {
    const top = durationContainer.offsetTop;
    const rect = durationContainer.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const containerRight = rect.right;
    const spaceOnRight = windowWidth - containerRight;
    const spaceOnLeft = rect.left;
    const extraContainerWidth = 180; // Width of the extra container

    if (spaceOnRight < extraContainerWidth + 50 && spaceOnLeft >= extraContainerWidth + 50) {
      // Position to the left if there's not enough space on the right
      extraContainer.style.left = `${rect.left - extraContainerWidth - 10}px`;
    } else {
      // Default positioning to the right
      extraContainer.style.left = `${rect.left + rect.width + 10}px`;
    }

    extraContainer.style.top = `${top}px`;
  }

  startLongPress(): void {
    this.longPressTimeout = setTimeout(() => {
      this.showAllTimes();
    }, 400);
  }

  showAllTimes(): void {
    // Implement the logic for showing all times here
    console.log('Showing all times');
    this.extraOpened = !this.extraOpened;
    if (this.extraOpened) {
      // Hide all other extra containers
      const allExtraContainers = document.querySelectorAll('.extra-container');
      allExtraContainers.forEach((container: Element) => {
        if (container instanceof HTMLElement && container !== this.durationExtraBox.nativeElement) {
          container.classList.add('hide');
        }
      });

      // Ensure the current extra container is visible
      this.durationExtraBox.nativeElement.classList.remove('hide');

      // Update the position of the extra container
      setTimeout(() => {
        const durationContainer = this.durationBox.nativeElement;
        const extraContainer = this.durationExtraBox.nativeElement;
        this.updateExtraContainerPosition(durationContainer, extraContainer);
      });
    }
  }

  endLongPress(): void {
    clearTimeout(this.longPressTimeout);
  }

  startTimer() {
    this.isStarted = true;
    this.startTime = Date.now();
    this.updateTimer();
  }

  private updateTimer(): void {
    if (this.isStarted) {
      const currentTime = Date.now();
      this.timerValue = Math.floor((currentTime - this.startTime) / 1000);
      setTimeout(() => this.updateTimer(), 1000);
    }
  }



  stopTimer() {
    if (this.timerValue > 0) {
      const currentDate = new Date();
      const formattedDate = this.formatDate(currentDate);
      const formattedTime = this.timerString();
      this.times.unshift(`${formattedTime} ${formattedDate}`);
      this.amountOfTimes = this.times.length;
    }
    this.timerValue = 0;
    this.isStarted = false;
  }

  timerString() {
    const minutes = Math.floor(this.timerValue / 60);
    const seconds = this.timerValue % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  closeExtra() {
    this.extraOpened = false;
  }

  private formatDate(date: Date): string {


    
    const today = new Date();
    if (date.toDateString() === today.toDateString()) {
      return 'at '+date.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }).toLowerCase();
    } else {
      return 'on '+date.toLocaleString('en-US', {
        day: 'numeric',
        month: 'short',
        year: '2-digit'
      });
    }
  }

}