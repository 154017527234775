<ul id="skillAreas">
    @if (loadingSkillAreas) {
        <app-loading-indicator />
    } @else {
        @for (skillArea of skillAreas; track skillArea.ID) {
            <li>
                <p>{{ skillArea.SkillAreaName }}</p>
            </li>
        } @empty {
            <p style="text-align: center; margin-top: 20px; font-size: 1rem; color: #719eb6;">There are no Skill Areas yet.</p>
        }
    }
</ul>
