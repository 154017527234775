import { HttpClient, HttpErrorResponse, HttpResponse, HttpParams } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Subject, Observable, catchError, map, of, tap } from "rxjs";
import { environment } from '../../environments/environment';
import { UserModel, GeneralSettingsModel, JwTokenModel, Permissions } from "../core/api/models";
import { AccountApiService  } from '../core/api/services';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  accountApiService = inject(AccountApiService);

  public tokenInfo$: Subject<JwTokenModel | null> = new Subject<JwTokenModel | null>();
  public tokenInfo: JwTokenModel | null = null;

  
  public userInfo$: Subject<UserModel | null> = new Subject<UserModel | null>();
  public userInfo: UserModel | null = null;

  public generalSettings$: Subject<GeneralSettingsModel | null> = new Subject<GeneralSettingsModel | null>();
  public generalSettings: GeneralSettingsModel | null = null;
    
  private _authStateChanged: Subject<boolean> = new BehaviorSubject<boolean>(false);

  public onStateChanged() {
    return this._authStateChanged.asObservable();
  }
  
  constructor(private http: HttpClient, private router: Router,
    private route: ActivatedRoute) {

    const tokenInfoData = localStorage.getItem('tokenInfo');
    if (tokenInfoData) {
      this.tokenInfo = JSON.parse(tokenInfoData) as JwTokenModel;
      console.info('Has Token Info');
      this.tokenInfo$.next(this.tokenInfo);
    } else {
      this.tokenInfo$.next(null);
    }

    const currentUserData = localStorage.getItem('userInfo');
    if (currentUserData) {
      this.userInfo = JSON.parse(currentUserData) as UserModel;
      console.info('Has User Info');
      this.userInfo$.next(this.userInfo);
    } else {
      this.userInfo$.next(null);
    }

    const generalSettingsData = localStorage.getItem('generalSettings');
    if (generalSettingsData) {
      this.generalSettings = JSON.parse(generalSettingsData) as GeneralSettingsModel;
      console.info('Has General Settings');
      this.generalSettings$.next(this.generalSettings);
    } else {
      this.generalSettings$.next(null);
    }

    this.tokenInfo$.subscribe(x => {
      console.debug('tokenInfo$', x);
      if (x) {
        localStorage.setItem('tokenInfo', JSON.stringify(x));
      }
      else
        localStorage.removeItem('tokenInfo');
        this.tokenInfo = x;
    });

    this.userInfo$.subscribe(x => {
      console.debug('userInfo$', x);
      if (x) {
        localStorage.setItem('userInfo', JSON.stringify(x));
      }
      else
        localStorage.removeItem('userInfo');
      this.userInfo = x;
    });

    this.generalSettings$.subscribe(x => {
      console.debug('generalSettings$', x);
      if (x) {
        localStorage.setItem('generalSettings', JSON.stringify(x));
      }
      else
        localStorage.removeItem('generalSettings');
      this.generalSettings = x;
    });
  }


  public signIn(email: string, password: string) {


    return this.accountApiService.Authenticate({ body: { username: email, password: password } })
      .pipe(tap(token => {
        if (token && token.access_token) {
          this.tokenInfo$.next(token);
          this._authStateChanged.next(true);
        } else {
          this.tokenInfo$.next(null);
          this._authStateChanged.next(false);
        }
        return ;
      },
        error => {
          console.log(error);
          //this.messageService.add({ severity: 'error', detail: 'Internal Error: ' + (error.message || error) });
        }));
  }



  // sign out
  public signOut() {


    this._authStateChanged.next(false);
    const currenttokenInfo = JSON.parse(localStorage.getItem('tokenInfo') || '{}');

    this.accountApiService.Logout()
      .forEach(() => { })
      .catch( err => { })
      .finally(() => { });

    this.tokenInfo$.next(null);
    this.userInfo$.next(null);
    this._authStateChanged.next(false);

    var url = this.getOptoURL();
    if (url)
      location.href = url + '/login/logout';
    else
      this.router.navigate(['/signin']);

    return of(true);
     
  }


  
  public isSignedIn(): boolean {


    if (this.tokenInfo != null
      && this.tokenInfo.expires_at != null
      && new Date(this.tokenInfo.expires_at) > new Date()) {
      return true;
    }
    return false;
  }


  public redirectAfterLogin(userInfo?: UserModel | null, returnUrl?: string | undefined | null) {
    userInfo = userInfo || this.userInfo;
    if (userInfo == null || userInfo == undefined) 
      this.router.navigate(['/signin']);
    else if (returnUrl && returnUrl != '' && returnUrl != '/' && returnUrl != '/signin')
      this.router.navigate([returnUrl]);
    else if (userInfo.Permissions != null  && userInfo.Permissions.indexOf(Permissions.SparkApp_Supervisor) >= 0) 
     this.router.navigate(['/supervisor']);
    else 
      this.router.navigate(['/staff']);
    
  }


  private _gettingTokenInfo = false;
  public getTokenInfo(): Observable<JwTokenModel | null> {

    this._gettingTokenInfo = true;
    var refresh_token: string | undefined = undefined;

    if (this.tokenInfo != null
      && this.tokenInfo.refresh_token != null
      && this.tokenInfo.refresh_expires_at != null
      && new Date(this.tokenInfo.refresh_expires_at) > new Date())
      refresh_token = this.tokenInfo.refresh_token!;

    return this.accountApiService.RefreshToken({ body: { refresh_token: refresh_token } })

      .pipe(tap(
        (result) => {
          this._gettingTokenInfo = false;
          this.tokenInfo$.next(result);
        },
        (err) => {
          this._gettingTokenInfo = false;
          this.userInfo$.next(null);
          this.tokenInfo$.next(null);
          return of(null);
        }));
  }

  public token() {
    if (this.tokenInfo) {
      return of(this.tokenInfo);
    }
    if (this._gettingTokenInfo)
      return this.tokenInfo$.asObservable();

    return this.getTokenInfo().pipe(
      map((tokenInfo) => {
        if (tokenInfo) {
          return tokenInfo;
        }
        return null;
      }));
  }


  private _gettingUserInfo = false;
  public getUserInfo(): Observable<UserModel | null> {


    this._gettingUserInfo = true;
    return this.accountApiService.Me()

      .pipe(tap(
        (result) => {
          this._gettingUserInfo = false;
          this.userInfo$.next(result);
        },
        (err) => {
          this._gettingUserInfo = false;
          this.userInfo$.next(null);
          return of(null);
        }));
  }
  
  public user() {
    if (this.userInfo) {
      return of(this.userInfo);
    }
    if (this._gettingUserInfo)
      return this.userInfo$.asObservable();

    return this.getUserInfo().pipe(
      map((userInfo) => {
        if (userInfo) {
          return userInfo;
        }
        return null;
      }));
  }

  private _gettingSettings = false;
  public getSettings(): Observable<GeneralSettingsModel | null> {

    this._gettingSettings = true;
    return this.accountApiService.GeneralSettings()

      .pipe(tap(
        (result) => {
          this._gettingSettings = false;
          this.generalSettings$.next(result);
        },
        (err) => {
          this._gettingSettings = false;
          this.generalSettings$.next(null);
          return of(null);
        }));
  }

  public settings() {
    if (this.generalSettings) {
      return of(this.generalSettings);
    }
    if (this._gettingSettings)
      return this.generalSettings$.asObservable();
    return this.getSettings().pipe(
      map((generalSettings) => {
        if (generalSettings) {
          return generalSettings;
        }
        return null;
      }));
  }



  public getClientFromUrl() {
    const url = window.location.hostname.split('.')[0].toLowerCase();
    if (url.startsWith('spark-'))
      return url.substring(6);
    return url;
  }

  public getOptoURL() {

    var host = window.location.hostname.toLowerCase();

    if (host.startsWith('spark-'))
      return 'https://' + host.substring(6);
    // if (host == 'localhost')
    //   return 'https://localhost:44354/';
    if (environment.production && this.generalSettings?.SiteName)
      return `https://${this.generalSettings?.SiteName}.optomiser.com`;
    return null;
  }

  
  /*
  public setCookie(cname: string, cvalue: string, exdays: number) {
    var expires = "";
    if (exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      expires = ";expires=" + d.toUTCString();
    }
    document.cookie = cname + "=" + cvalue + expires + ";path=/";
  }

  public getCookie(cname: string) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  */


}
