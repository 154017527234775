@if (isCollapsed) {
    <div class="container" (click)="toggleCollapsed()">
        <div class="row h-4rem align-items-center">
            <div class="col-3">
                <button type="button" class="btn btn-primary position-relative program-name">
                    {{ program?.ProgramName }}
                    <!-- @if ((program?.alerts?.length ?? 0) > 0) {
                        <span class="position-absolute top-50 end-0 translate-middle-y p-2 bg-danger border border-light rounded-circle" style="background-color: #9382AD !important; margin-right: 1rem">
                          <span class="visually-hidden">New alerts</span>
                        </span>
                    } -->
                  </button>
            </div>
            <div class="col-1">
                <i class="pi pi-arrow-down" style="font-size: 1rem; color: #719eb6;"></i>
            </div>
        </div>
    </div>
} @else {
    <div class="container">
        <div class="row h-4rem align-items-center">
            <div class="col-3">
                <button type="button" class="btn btn-primary position-relative program-name" (click)="toggleCollapsed()">
                    {{ program?.ProgramName }}
                    <!-- @if ((program?.alerts?.length ?? 0) > 0) {
                        <span class="position-absolute top-50 end-0 translate-middle-y p-2 bg-danger border border-light rounded-circle" style="background-color: #9382AD !important; margin-right: 1rem">
                          <span class="visually-hidden">New alerts</span>
                        </span>
                    } -->
                  </button>
            </div>
            <div class="col-1">
                <i class="pi pi-arrow-up" style="font-size: 1rem; color: #719eb6;"></i>
            </div>
            <div class="col-3">
                <span class="fs-8 fw-bolder" style="color: #719eb6;"> Included Playlists: </span>
                <span class="fs-8 " style="color: #719eb6;"> {{ playlists }} </span>
            </div>
            <div class="col-2">
                <span class="fs-8 fw-bolder" style="color: #719eb6;"> Skill Areas: </span>
                <span class="fs-8 " style="color: #719eb6;"> {{ skillAreas }} </span>
            </div>
            <div class="col-3">
                <div class="side-menu-buttons">
                    <button (click)="editProgram()" class="side-menu-button2">Edit Program</button>
                    <button (click)="deleteProgram()" class="switch-mode-button">Delete Program</button>
                </div>
            </div>

        </div>
        <!-- @for (alert of program?.alerts; track alert.id) {
            <div class="row">
                <div class="program-alert">
                    <span class="fs-8 fw-bold col-5"> {{ alert.staffName }} </span>
                    <span class="fs-8 col-6"> {{ alert.message }} </span>
                    <button (click)="dismissAlertButtonClicked(alert)" class="side-menu-button1">Dismiss</button>
                </div>
            </div>
        } -->
        
        <div class="row">
            <span class="fs-8 fw-bolder" style="color: #719eb6;">Details(s): </span>
            <span class="fs-8 " style="color: #719eb6;"> {{ program?.Details }} </span>
        </div>
        <div class="row">
            @for (target of program?.Targets; track target.TargetName) {
                <app-target [program]="program" [target]="target" />
            } @empty {
                <p>There are no targets for this program yet.</p>
            }
        </div>
    </div>
}
