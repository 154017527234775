import { Component, input, OnInit, WritableSignal } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../auth';
import {
  PatientModel,
  PatientProgramModel,
  UserModel,
} from '../core/api/models';
import { HealthCheckApiService } from '../core/api/services/health-check-api.service';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { SupervisorApiService } from '../core/api/services';
import { AlertService } from '../shared/services/alert.service';
import { Utils } from '../core/utils';
import { DialogHelperService } from './dialog.helper.service';

@Component({
  selector: 'supervisor-main',
  templateUrl: './supervisor-main.component.html',
  styleUrl: './supervisor-main.component.css',
})
export class SupervisorMainComponent implements OnInit {
  patientId = input<string>();

  loadingPatients: boolean = false;
  patients: any[] | undefined;
  selectedPatient: PatientModel | null = null;

  filteredPatients: any[] = [];

  serverInfo: any = null;
  userInfo: UserModel | null = null;

  pageTitle: string | null = null;
  loading: boolean = false;

  showAddProgram = this.dialogHelperService.showAddProgram;
  showAddStaffAlert = this.dialogHelperService.showAddStaffAlert;
  showAddPlaylist = this.dialogHelperService.showAddPlaylist;
  showAddTarget = this.dialogHelperService.showAddTarget;
  showAddSkillArea = this.dialogHelperService.showAddSkillArea;
  showAddReminder = this.dialogHelperService.showAddReminder;

  constructor(
    private authService: AuthService,
    private healthCheckApiService: HealthCheckApiService,
    private router: Router,
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    private dialogHelperService: DialogHelperService
  ) {}

  ngOnInit() {
    this.authService.user().subscribe((user) => (this.userInfo = user));
    this.serverInfo = 'Loading';
    this.loadingPatients = true;

    this.supervisorApiService.GetAllPatients().subscribe(
      (patients) => {
        this.patients = patients;
        this.loadingPatients = false;
        // TODO: remove this later, just for testing.
        this.selectedPatient = patients.find((p) => p.ID == 6588) ?? null;
        this.router.navigate(['/supervisor', 6588]);
      },
      (err) => {
        this.alertService.error(Utils.getAjaxError(err));
        this.loadingPatients = false;
      }
    );
  }

  isRouteActive(route: string): boolean {
    return this.router.url.includes(route);
  }

  onPatientSelected(event: any) {
    this.router.navigate(['/supervisor', this.selectedPatient?.ID]);
  }

  switchToStaff() {
    this.router.navigate(['/staff']);
  }

  staffAlertButtonClicked() {
    console.log('staffAlertButtonClicked');
    this.dialogHelperService.staffAlertButtonClicked();
  }

  settingsButtonClicked() {
    console.log('settingsButtonClicked');
    this.selectedPatient = null;
    this.router.navigate(['/supervisor/null/settings']);
  }

  addProgramButtonClicked() {
    console.log('addProgramButtonClicked');
    this.dialogHelperService.additProgramButtonClicked(null);
  }

  addPlaylistButtonClicked() {
    console.log('addPlaylistButtonClicked');
    this.dialogHelperService.additPlaylistButtonClicked(null);
  }

  addTargetButtonClicked() {
    console.log('addTargetButtonClicked');
    this.dialogHelperService.additTargetButtonClicked(null);
  }

  addSkillAreaButtonClicked() {
    console.log('addSkillAreaButtonClicked');
    this.dialogHelperService.addSkillAreaButtonClicked(null);
  }

  addReminderButtonClicked() {
    console.log('addReminderButtonClicked');
    this.dialogHelperService.addReminderButtonClicked(null);
  }

  onCloseAddSkillArea() {
    console.log('onCloseAddSkillArea');
    this.dialogHelperService.onCloseAddSkillArea();
  }

  onCloseAddPlaylist() {
    console.log('onCloseAddPlaylist');
    this.dialogHelperService.onCloseAddPlaylist();
  }

  onCloseAddTarget() {
    console.log('onCloseAddTarget');
    this.dialogHelperService.onCloseAddTarget();
  }

  onCloseAddProgram() {
    console.log('onCloseAddProgram');
    this.dialogHelperService.onCloseAddProgram();
  }

  onCloseAddStaffAlert() {
    console.log('onCloseAddStaffAlert');
    this.dialogHelperService.onCloseAddStaffAlert();
  }

  onCloseAddReminder() {
    console.log('onCloseAddReminder');
    this.dialogHelperService.onCloseAddReminder();
  }

  activated(comp: any) {
    this.pageTitle = comp.pageTitle || '&nbsp;';
    if (comp instanceof SupervisorMainComponent) {
      let tComp = comp as SupervisorMainComponent;
      tComp.userInfo = this.userInfo;
    }
  }
}
