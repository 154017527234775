import { Component, EventEmitter, Output } from '@angular/core';
import { SkillAreaModel } from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { DialogHelperService } from '../../dialog.helper.service';
import { AlertService } from '../../../shared/services/alert.service';
import { Utils } from '../../../core/utils';

@Component({
  selector: 'app-add-skill-area',
  templateUrl: './add-skill-area.component.html',
  styleUrl: './add-skill-area.component.css',
})
export class AddSkillAreaComponent {
  skillAreaToEdit: SkillAreaModel | null = null;

  @Output() close = new EventEmitter<void>();

  loading: boolean = false;

  titleName: string = 'Add Skill Area';
  actionName: string = 'Add';

  skillAreaId: number | null = null;
  enteredName = '';

  constructor(
    private supervisorApiService: SupervisorApiService,
    private dialogHelperService: DialogHelperService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    // EDIT LOGIC
    if (this.dialogHelperService.programToEdit() != null) {
      this.titleName = 'Edit Program';
      this.actionName = 'Save';
      this.enteredName = this.skillAreaToEdit?.SkillAreaName ?? '';
    }
  }

  onCancel() {
    this.close.emit();
  }

  onSubmit() {
    this.loading = true;
    this.supervisorApiService
      .SaveSkillArea({
        body: {
          ID: this.skillAreaId,
          SkillAreaName: this.enteredName,
        },
      })
      .subscribe(
        (skillArea) => {
          console.log(skillArea);
          this.loading = false;
          this.alertService.success('Skill Area saved successfully');
          this.close.emit();
        },
        (error) => {
          console.error(error);
          this.alertService.error(Utils.getAjaxError(error));
          this.loading = false;
          this.close.emit();
        }
      );
  }
}
