import { Component, effect, input } from '@angular/core';

import { PatientTargetModel, TargetTemplateModel } from '../../core/api/models';
import { SupervisorApiService } from '../../core/api/services';
import { AlertService } from '../../shared/services/alert.service';
import { Utils } from '../../core/utils';
import { DialogHelperService } from '../dialog.helper.service';

@Component({
  selector: 'app-targets',
  templateUrl: './targets.component.html',
  styleUrl: '../supervisor-main.component.css'
})
export class TargetsComponent {

  patientId = input<number>();
  
  targets: PatientTargetModel[] = [];

  loadingTargets: boolean = false;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    private dialogHelperService: DialogHelperService,
  ) {
    effect(() => {
      console.log('update signal triggered: ', this.dialogHelperService.updateSignal());
      this.loadData();
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loadingTargets = true;
    this.supervisorApiService.GetPatientTargets({ patientID: this.patientId() })
    .subscribe(
      (targets) => {
        this.loadingTargets = false;
        this.targets = targets;
      },
      (err) => {
        this.loadingTargets = false;
         this.alertService.error(Utils.getAjaxError(err));
      });
  }

}
