import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, effect, inject } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { HealthCheckApiService, StaffApiService, SupervisorApiService } from '../../core/api/services';
import { Router } from '@angular/router';
import { PatientModel, PatientPlaylistModel, PatientProgramModel, PatientReminderModel, PatientSessionModel, PatientTargetModel, PatientTargetStatus, TargetType } from '../../core/api/models';
import { PatientPlaylistWithItemsModel, PatientProgramWithCountersModel, PatientReminderWithLastTimeModel } from '../../shared/models/models';
import { getPatientTargetStatusColor } from '../../shared/utils/utils';
import { forkJoin } from 'rxjs';
import { PatientSessionService } from './patient-session.service';

@Component({
  selector: 'staff-home',
  templateUrl: './staff-home.component.html',
  styleUrls: ['./staff-home.component.css']
})
export class StaffHomeComponent implements OnInit {

  isTimerRunning: boolean = false;
  timerValue: number = 0;
  patientSession: PatientSessionModel | null = null;
  programs: PatientProgramWithCountersModel[] = [];
  playlists: PatientPlaylistWithItemsModel[] = [];
  targets: PatientTargetModel[] = [];
  favoriteTargets: PatientTargetModel[] = [];
  patientID: number = 6588;//TODO: get from URL
  showingItems: { Title: string, Name: string, Targets: PatientTargetModel[] }[] = [];
  patient: PatientModel | null = null;
  reminders: PatientReminderWithLastTimeModel[] = [];
  showingReminders: PatientReminderWithLastTimeModel[] = [];

  listedItems: (PatientProgramModel | PatientTargetModel | PatientPlaylistModel)[] = [];
  selectedItemsIndexes: number[] = [];

  selectedProgramIndex: number = -1;
  selectedPlaylistIndex: number = -1;
  selectedTargetIndex: number = -1;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private supervisorApiService: SupervisorApiService,
    private router: Router,
    private staffApiService: StaffApiService,
    private patientSessionService: PatientSessionService) {

    effect(() => {
      this.patientSession = this.patientSessionService.patientSession();
      if (this.isStarted() && !this.isTimerRunning) {
        this.updateTimer();
      }
    });

  }


  ngOnInit() {
    this.getData();

  }

  getData(): void {

    const getProgramsObservable = this.supervisorApiService.GetPatientPrograms({ patientID: this.patientID });
    const getPlaylistsObservable = this.supervisorApiService.GetPatientPlaylists({ patientID: this.patientID });
    const getTargetsObservable = this.supervisorApiService.GetPatientTargets({ patientID: this.patientID });

    forkJoin([getProgramsObservable, getPlaylistsObservable, getTargetsObservable]).subscribe(([programs, playlists, targets]) => {
      this.programs = programs as PatientProgramWithCountersModel[];
      this.playlists = playlists as PatientPlaylistWithItemsModel[];
      this.targets = targets;

      this.programs.forEach(program => {
        const counters: { targetStatus: PatientTargetStatus, count: number, color: string }[] = [];
        program.Targets?.forEach(target => {
          if (target.TargetStatus || target.TargetStatus == PatientTargetStatus.Unspecified) {
            if (!counters.find(x => x.targetStatus == target.TargetStatus)) {
              counters.push({ targetStatus: target.TargetStatus, count: 1, color: getPatientTargetStatusColor(target.TargetStatus) });
            } else {
              //increment the count
              const index = counters.findIndex(x => x.targetStatus == target.TargetStatus);
              counters[index].count++;
            }
          }
        });
        program.Counters = counters;
      });
      this.listedItems = [...this.programs];

      this.playlists.forEach(playlist => {
        playlist.FilledItems = [];
        playlist.Items?.forEach(item => {
          const program = this.programs.find(p => p.ID === item.PatientProgramID);
          if (program) {
            playlist.FilledItems?.push(program);
          } else {
            const target = this.targets.find(t => t.ID === item.PatientTargetID);
            if (target) {
              playlist.FilledItems?.push(target);
            }
          }
        });
      });

      this.listedItems = [...this.playlists];


      this.favoriteTargets = targets.filter(t => t.IsFavorite);
      this.listedItems = [...this.targets];

      if (this.programs.length > 0) {
        this.selectProgram(this.programs[0]);
      } else if (this.playlists.length > 0) {
        this.selectPlaylist(this.playlists[0]);
      } else if (this.targets.length > 0) {
        this.selectTarget(this.targets[0]);
      }
    });


    this.staffApiService.GetPatientInfo({ id: this.patientID }).subscribe((patient) => {
      this.patient = patient;
    });
    this.supervisorApiService.GetPatientReminders({ patientID: this.patientID }).subscribe((reminders) => {
      this.reminders = reminders.map(r => ({ ...r, lastTimeClosed: 0 }));
    });

  }

  countMasteredTargets(program: PatientProgramModel): number {
    return program.Targets?.filter(target => !!target.DateMastered).length || 0;
  }

  startSession() {

    this.patientSessionService.startSession(this.patientID);

  }

  isStarted(): boolean {
    return !!(this.patientSession?.StartedDate && !this.patientSession?.FinishedDate);
  }

  private updateTimer(): void {
    if (this.isStarted()) {
      this.isTimerRunning = true;
      const currentTime = Date.now();
      const startTime = new Date(this.patientSession?.StartedDate!).getTime();
      this.timerValue = Math.floor((currentTime - startTime) / 1000);

      //check if the reminder should be shown based on its interval and the sesstion time
      this.checkReminders();
      setTimeout(() => this.updateTimer(), 1000);
    }
  }

  private checkReminders(): void {
    this.showingReminders = this.reminders.filter(r =>
      !r.Interval && !r.lastTimeClosed ||
      r.Interval && (this.timerValue - r.lastTimeClosed) >= r.Interval);
  }

  closeReminder(reminder: PatientReminderWithLastTimeModel) {
    //increase the reminder counter
    if (reminder.Interval) {
      reminder.lastTimeClosed = Math.floor(this.timerValue / reminder.Interval) * reminder.Interval;
    } else {
      reminder.lastTimeClosed = this.timerValue;
    }
    this.checkReminders();
  }


  endSession() {
    this.patientSessionService.endSession();
    this.isTimerRunning = false;
    this.timerValue = 0;
  }

  timerString() {
    const minutes = Math.floor(this.timerValue / 60);
    const seconds = this.timerValue % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  switchToSupervisor() {
    this.router.navigate(['/supervisor/null']);
  }

  getShowingItemsGroupedByTitle() {
    const groupedItems = this.showingItems.reduce((acc: { Title: string; items: { Title: string; Name: string; Targets: PatientTargetModel[]; }[]; }[], item) => {
      const existing = acc.find(group => group.Title === item.Title);
      if (!existing) {
        acc.push({ Title: item.Title, items: [item] });
      } else {
        existing.items.push(item);
      }
      return acc;
    }, []);

    return groupedItems;

  }

  selectProgram(program: PatientProgramModel) {
    this.showingItems = [{ Title: program.SkillAreaName ?? '', Name: program.ProgramName ?? '', Targets: program.Targets ?? [] }];
    this.selectedProgramIndex = this.programs.findIndex(p => p.ID === program.ID);
    this.selectedPlaylistIndex = -1;
    this.selectedTargetIndex = -1;
  }

  selectPlaylist(playlist: PatientPlaylistModel) {
    const programs: PatientProgramModel[] = [];
    playlist.Items?.forEach(item => {
      const program = this.programs.find(p => p.ID === item.PatientProgramID);
      if (program) {
        programs.push(program);
      } else {
        const target = this.targets.find(t => t.ID === item.PatientTargetID);
        if (target) {
          programs.push({ SkillAreaName: 'Targets', ProgramName: target.TargetName, Targets: [target] });
        }
      }
    });
    this.showingItems = programs.map(p => ({ Title: p.SkillAreaName ?? '', Name: p.ProgramName ?? '', Targets: p.Targets ?? [] }));
    this.selectedPlaylistIndex = this.playlists.findIndex(p => p.ID === playlist.ID);
    this.selectedProgramIndex = -1;
    this.selectedTargetIndex = -1;
  }

  selectTarget(target: PatientTargetModel) {
    this.showingItems = [{ Title: 'Targets', Name: target.TargetName ?? '', Targets: [target] }];
    this.selectedTargetIndex = this.targets.findIndex(t => t.ID === target.ID);
    this.selectedProgramIndex = -1;
    this.selectedPlaylistIndex = -1;
  }

  isDurationTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.Duration;
  }

  isFrequencyTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.Frequency;
  }

  isDttTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.DiscreteTrialTeaching;
  }

  isConditionalDiscriminationTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.ConditionalDiscrimination;
  }

  isEchoicTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.Echoic;
  }

  isFluencyRateTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.FluencyRate;
  }

  isGroupedFrequencyTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.GroupedFrequency;
  }

  isJumpToTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.JumpTo;
  }

  isQuantityTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.Quantity;
  }

  isTaskAnalysisTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.TaskAnalysis;
  }

  isToiletingTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.Toileting;
  }

  isWholePartialIntervalTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.WholePartialInterval;
  }

  isAnecdotalTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.Anecdotal;
  }

  isDiscreteTrialTeachingTarget(target: PatientTargetModel) {
    return target.TargetType === TargetType.DiscreteTrialTeaching;
  }

  nextOnList() {
    if (this.selectedTargetIndex >= 0) {
      this.nextTarget();
    } else if (this.selectedPlaylistIndex >= 0) {
      this.nextPlaylist();
    } else {
      if (this.selectedProgramIndex < 0) {
        this.selectedProgramIndex = -1;
      }
      this.nextProgram();
    }
  }

  nextProgram() {
    if (this.selectedProgramIndex + 1 >= this.programs.length) {
      this.selectedProgramIndex = -1;
      this.selectedTargetIndex = -1;
      this.selectedPlaylistIndex = -1;
      this.nextPlaylist();
    } else {
      this.selectedProgramIndex++;
      this.selectProgram(this.programs[this.selectedProgramIndex]);
    }
  }

  nextPlaylist() {
    if (this.selectedPlaylistIndex + 1 >= this.playlists.length) {
      this.selectedPlaylistIndex = -1;
      this.selectedProgramIndex = -1;
      this.selectedTargetIndex = -1;
      this.nextTarget();
    } else {
      this.selectedPlaylistIndex++;
      this.selectPlaylist(this.playlists[this.selectedPlaylistIndex]);
    }
  }

  nextTarget() {
    if (this.selectedTargetIndex + 1 >= this.targets.length) {
      //Nothing this is the end
    } else {
      this.selectedTargetIndex++;
      this.selectTarget(this.targets[this.selectedTargetIndex]);
    }
  }
}
