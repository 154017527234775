@if (showAddProgram()) {
    <div class="supervisor-dialog-backdrop" (click)="onCloseAddProgram()"></div>
    <dialog class="supervisor-dialog" open>
        <app-add-program [selectedPatient]="selectedPatient" (close)="onCloseAddProgram()" />
    </dialog>
}

@if (showAddPlaylist()) {
    <div class="supervisor-dialog-backdrop" (click)="onCloseAddPlaylist()"></div>
    <dialog class="supervisor-dialog" open>
        <app-add-playlist [selectedPatient]="selectedPatient" (close)="onCloseAddPlaylist()" />
    </dialog>
}

@if (showAddTarget()) {
    <div class="supervisor-dialog-backdrop" (click)="onCloseAddTarget()"></div>
    <dialog class="supervisor-dialog" open>
        <app-add-target [selectedPatient]="selectedPatient" (close)="onCloseAddTarget()" />
    </dialog>
}

@if (showAddStaffAlert()) {
    <div class="supervisor-dialog-backdrop" (click)="onCloseAddStaffAlert()"></div>
    <dialog class="supervisor-dialog" open>
        <app-add-staff-alert (close)="onCloseAddStaffAlert()" />
    </dialog>
}

@if (showAddSkillArea()) {
    <div class="supervisor-dialog-backdrop" (click)="onCloseAddSkillArea()"></div>
    <dialog class="supervisor-dialog" open>
        <app-add-skill-area (close)="onCloseAddSkillArea()" />
    </dialog>
}

@if (showAddReminder()) {
    <div class="supervisor-dialog-backdrop" (click)="onCloseAddReminder()"></div>
    <dialog class="supervisor-dialog" open>
        <app-add-reminder (close)="onCloseAddReminder()" />
    </dialog>
}

<div class="dashboard">
    <div class="header">
        <div class="side-menu-buttons">
            <div class="client-name">
                <p-dropdown 
                    [options]="patients"
                    [(ngModel)]="selectedPatient"
                    optionLabel="FirstName"
                    [filter]="true"
                    filterBy="FirstName" 
                    [showClear]="true"
                    [virtualScroll]="true"
                    [virtualScrollItemSize]="50"
                    [loading]="loadingPatients"
                    (onChange)="onPatientSelected($event)"
                    placeholder="Select a Patient">
                        <ng-template pTemplate="selectedPatient" let-selectedOption>
                            <div class="flex align-items-center gap-2">
                                <div>{{ selectedOption.FirstName }}</div>
                            </div>
                        </ng-template>
                </p-dropdown>
            </div>
            
            <div class="horizontal-flex-list">
                @if (selectedPatient) {
                    <button (click)="addProgramButtonClicked()" class="side-menu-button2">Add Program</button>
                    <button (click)="addPlaylistButtonClicked()" class="side-menu-button2">Add Playlist</button>
                    <button (click)="addTargetButtonClicked()" class="side-menu-button2">Add Target</button>
                    <button (click)="addReminderButtonClicked()" class="side-menu-button2">Add Reminder</button>
                }
                <button (click)="addSkillAreaButtonClicked()" class="side-menu-button2">Add Skill Area</button>
            </div>
        </div>
        <div class="side-menu-buttons">
            <button (click)="switchToStaff()" class="switch-mode-button">Switch to Staff</button>
            <button (click)="staffAlertButtonClicked()" class="side-menu-button1">Staff Alert</button>
            <button (click)="settingsButtonClicked()" class="side-menu-button2">Settings</button>
        </div>
    </div>
    @if (selectedPatient) {
        <div class="modules-container">
            <div class="horizontal-flex-list">
                <a [routerLink]="['/supervisor', selectedPatient.ID, 'programs']" routerLinkActive="selected" >
                    <span>Programs</span>
                </a>
                <a [routerLink]="['/supervisor', selectedPatient.ID, 'playlists']" routerLinkActive="selected" >
                    <span>Playlists</span>
                </a>
                <a [routerLink]="['/supervisor', selectedPatient.ID, 'targets']" routerLinkActive="selected" >
                    <span>Targets</span>
                </a>

                <a [routerLink]="['/supervisor', selectedPatient.ID, 'reminders']" routerLinkActive="selected" >
                    <span>Reminders</span>
                </a>
            </div>
    
            <router-outlet (activate)="activated($event)"></router-outlet>
        </div>
    } @else {
        @if (isRouteActive('settings') || isRouteActive('library') || isRouteActive('skillAreas')) {
            <div class="modules-container">
                <div class="horizontal-flex-list">
                    <a [routerLink]="['/supervisor', null, 'settings']" routerLinkActive="selected" >
                        <span>General Settings</span>
                    </a>
                    <a [routerLink]="['/supervisor', null, 'library']" routerLinkActive="selected" >
                        <span>Library</span>
                    </a>
                    <a [routerLink]="['/supervisor', null, 'skillAreas']" routerLinkActive="selected" >
                        <span>Skill Areas</span>
                    </a>
                </div>
        
                <router-outlet (activate)="activated($event)"></router-outlet>
            </div>
        } @else {
            <div class="center-screen">
                <h1>ArkMed Spark</h1>
                <p>Choose a patient to get started.</p>
            </div>
        }
    }
</div>

<div class="container hide">
    <div class="row ">
        <div class="col-12">

        <div class="center-screen">
            <h1>IT'S FINE </h1>
            <p>ArkMed Spark is under construction. Please check back later.</p>
            <pre>{{serverInfo}}</pre>
        </div>
        </div>
    </div>
</div>