import {
  RouterModule,
  Routes,
  withComponentInputBinding,
} from '@angular/router';
import { AuthGuard } from '../core/auth.guard';
import { NgModule } from '@angular/core';

import { SupervisorMainComponent } from './supervisor-main.component';
import { ProgramsComponent } from './programs/programs.component';
import { PlaylistsComponent } from './playlists/playlists.component';
import { TargetsComponent } from './targets/targets.component';
import { SettingsComponent } from './settings/settings.component';
import { LibraryComponent } from './library/library.component';
import { SkillAreasComponent } from './skill-areas/skill-areas.component';
import { RemindersComponent } from './reminders/reminders.component';

const routes: Routes = [
  {
    path: 'supervisor',
    redirectTo: 'supervisor/:patientId',
    pathMatch: 'full',
  },

  {
    path: 'supervisor/:patientId',
    redirectTo: 'supervisor/:patientId/programs',
    pathMatch: 'full',
  },
  {
    path: 'supervisor/:patientId',
    component: SupervisorMainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'programs',
        component: ProgramsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'playlists',
        component: PlaylistsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'targets',
        component: TargetsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'reminders',
        component: RemindersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'library',
        component: LibraryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'skillAreas',
        component: SkillAreasComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class SupervisorRoutingModule {}
