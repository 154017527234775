import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, effect } from '@angular/core';
import { PatientSessionService } from '../patient-session.service';
import { PatientTargetDataModel } from '../../../core/api/models';

@Component({
  selector: 'app-box-frequency',
  templateUrl: './box-frequency.component.html',
})
export class BoxFrequencyComponent {
  @Input() text: string|undefined = '-';
  @Input() dayValue: number = 0;
  @Input() sessionValue: number = 0;
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  @Input() showDayValue: boolean = true;
  @Input() targetID: number = 0;

  targetData: PatientTargetDataModel|null=null;
  isEditing: boolean = false;
  private longPressTimeout: any;
  @ViewChild('inputField') inputField!: ElementRef;
  extraBoxes: ElementRef[] = [];

  constructor(
    private cdRef: ChangeDetectorRef, 
    private patientSessionService: PatientSessionService) { 
      effect(() => {
        const targetData = this.patientSessionService.getTargetData(this.targetID);
        if (targetData && targetData.length > 0) {
          this.targetData = targetData[0];
          this.sessionValue = targetData[0].IntValue || 0;
        }
        else {
          this.sessionValue = 0;
          this.targetData =null;
          }
        });
    }

  ngAfterViewInit() {
    this.extraBoxes = [];
    this.cdRef.detectChanges(); 
    
  }

  onClickOutside() {
    this.isEditing = false;
  }

  toggleEdit(): void {
    this.isEditing = !this.isEditing;
  }

  increaseSessionValue(): void {
    this.sessionValue++;   
    this.saveSessionValue();
  }

  saveSessionValue(): void {
    if (this.targetData) {
      this.targetData.IntValue = this.sessionValue;
      this.patientSessionService.saveTargetData(this.targetData);
    } else {
      this.targetData = {
        IntValue: this.sessionValue,
        PatientSessionID: 0,
        PatientTargetID: this.targetID,
        RecordedDate: new Date().toISOString(),
        TextValue: ''
      };
      this.patientSessionService.addTargetData(this.targetData);
    }
  }

  clickedBox(): void {
    if (!this.isEditing) {
      this.increaseSessionValue();
    }
  }

  decreaseSessionValue(): void {
    if (this.sessionValue > 0) {
      this.sessionValue--;
      this.saveSessionValue();
    }
  }

  startLongPress(): void {
    this.longPressTimeout = setTimeout(() => {
      this.toggleEdit();
    }, 400);
  }

  endLongPress(): void {
    clearTimeout(this.longPressTimeout);
    if (this.inputField && this.inputField.nativeElement) {
      this.inputField.nativeElement.focus();
    }
  }

  changedSessionValue(event: any): void {
    this.sessionValue = Number(event);
    console.log(this.sessionValue);
    this.saveSessionValue();
  }

}
