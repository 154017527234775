import { Component, EventEmitter, Input, Output } from '@angular/core';

import {
  PatientModel,
  PatientPlaylistItemModel,
  PatientPlaylistModel,
  PatientProgramModel,
} from '../../../core/api/models';
import { SupervisorApiService } from '../../../core/api/services';
import { AlertService } from '../../../shared/services/alert.service';
import { DialogHelperService } from '../../dialog.helper.service';
import { Utils } from '../../../core/utils';
import {
  CdkDragDrop,
  CdkDropList,
  CdkDrag,
  moveItemInArray,
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-add-playlist',
  templateUrl: './add-playlist.component.html',
  styleUrl: './add-playlist.component.css',
})
export class AddPlaylistComponent {
  @Input() selectedPatient: PatientModel | null = null;
  @Output() close = new EventEmitter<void>();

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedItems,
      event.previousIndex,
      event.currentIndex
    );
  }

  enteredName = '';
  starter: number = 0;

  loadingItems: boolean = false;
  availableItems: any[] = [];
  selectedItems: any[] = [];
  draggedItem: any | undefined | null;

  constructor(
    private supervisorApiService: SupervisorApiService,
    private alertService: AlertService,
    private dialogHelperService: DialogHelperService
  ) {}

  currentPlaylist?: PatientPlaylistModel;

  titleName: string = 'Add Playlist';
  actionName: string = 'Add';

  ngOnInit() {
    this.loadingItems = true;
    this.supervisorApiService
      .GetPatientPrograms({ patientID: this.selectedPatient?.ID })
      .subscribe(
        (programs) => {
          this.availableItems = programs;
          this.supervisorApiService
            .GetPatientTargets({ patientID: this.selectedPatient?.ID })
            .subscribe(
              (targets) => {
                this.availableItems = [...this.availableItems, ...targets];
                this.loadingItems = false;
              },
              (err) => {
                this.loadingItems = false;
                this.alertService.error(Utils.getAjaxError(err));
              }
            );
        },
        (err) => {
          this.loadingItems = false;
          this.alertService.error(Utils.getAjaxError(err));
        }
      );

    this.selectedItems = [];
  }

  onCancel() {
    this.currentPlaylist = undefined;
    this.close.emit();
  }

  onSubmit() {
    this.currentPlaylist = {
      ID: 0,
      PlaylistName: this.enteredName,
      PatientID: this.selectedPatient?.ID,
      Items: this.selectedItems.map((item, index) => {
        if (item.ProgramName) {
          return {
            ID: 0,
            OrderNr: index,
            PatientProgramID: item.ID,
            PatientTargetID: null,
          };
        } else {
          return {
            ID: 0,
            OrderNr: index,
            PatientProgramID: null,
            PatientTargetID: item.ID,
          };
        }
      }),
    };

    console.log(this.currentPlaylist);

    this.supervisorApiService
      .SavePatientPlaylist({ body: this.currentPlaylist })
      .subscribe(
        (savedItem) => {
          this.currentPlaylist = undefined;
          this.alertService.success('Playlist saved successfully');
          this.close.emit();
        },
        (err) => {
          this.alertService.error(Utils.getAjaxError(err));
          this.close.emit();
        }
      );
  }
}
