import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from "@angular/router";
import { AuthGuard } from '../core/auth.guard';
import {
  StaffMainComponent,
  StaffHomeComponent
  
} from './';


const routes: Routes = [
  { path: '', redirectTo: 'staff/home', pathMatch: 'full' },
  { path: 'staff', redirectTo: 'staff/home', pathMatch: 'full' },
  {
    path: 'staff', component: StaffMainComponent, canActivate: [AuthGuard], children: [
      { path: 'home', component: StaffHomeComponent, canActivate: [AuthGuard] }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class StaffRoutingModule { }
