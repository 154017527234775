/* tslint:disable */
/* eslint-disable */
export enum PatientTargetStatus {
  Unspecified = 0,
  Mastered = 1,
  Failed = 2,
  Waiting = 3,
  Aquisition = 4,
  Probe = 5
}
