import { effect, Injectable, signal } from '@angular/core';
import {
  PatientPlaylistItemModel,
  PatientPlaylistModel,
  PatientProgramModel,
  PatientReminderModel,
  PatientTargetModel,
  SkillAreaModel,
} from '../core/api/models';

@Injectable({
  providedIn: 'root',
})
export class DialogHelperService {
  updateSignal = signal(0);

  showAddProgram = signal(false);
  showAddStaffAlert = signal(false);
  showAddPlaylist = signal(false);
  showAddTarget = signal(false);
  showAddSkillArea = signal(false);
  showAddReminder = signal(false);

  programToEdit = signal<PatientProgramModel | null>(null);
  playlistToEdit = signal<PatientPlaylistModel | null>(null);
  targetToEdit = signal<PatientTargetModel | null>(null);
  skillAreaToEdit = signal<SkillAreaModel | null>(null);
  reminderToEdit = signal<PatientReminderModel | null>(null);

  constructor() {
    effect(() => {
      console.log('called', this.updateSignal());
    });
  }

  onCloseAddPlaylist() {
    this.showAddPlaylist.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  onCloseAddTarget() {
    this.showAddTarget.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  onCloseAddProgram() {
    this.showAddProgram.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  onCloseAddStaffAlert() {
    this.showAddStaffAlert.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  additProgramButtonClicked(programToEdit: PatientProgramModel | null) {
    this.programToEdit.set(programToEdit);
    this.showAddProgram.set(true);
  }

  additPlaylistButtonClicked(playlistToEdit: PatientPlaylistModel | null) {
    this.playlistToEdit.set(playlistToEdit);
    this.showAddPlaylist.set(true);
  }

  additTargetButtonClicked(targetToEdit: PatientTargetModel | null) {
    this.targetToEdit.set(targetToEdit);
    this.showAddTarget.set(true);
  }

  staffAlertButtonClicked() {
    this.showAddStaffAlert.set(true);
  }

  addSkillAreaButtonClicked(skillAreaToEdit: SkillAreaModel | null) {
    this.skillAreaToEdit.set(skillAreaToEdit);
    this.showAddSkillArea.set(true);
  }

  onCloseAddSkillArea() {
    this.showAddSkillArea.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }

  addReminderButtonClicked(reminderToEdit: PatientReminderModel | null) {
    this.reminderToEdit.set(reminderToEdit);
    this.showAddReminder.set(true);
  }

  onCloseAddReminder() {
    this.showAddReminder.set(false);
    this.updateSignal.update((oldValue) => oldValue + 1);
  }
}
