import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';

import { SupervisorMainComponent } from './supervisor-main.component';
import { SharedModule } from '../shared/shared.module';
import { SupervisorRoutingModule } from './supervisor.routing.module';
import { ProgramsComponent } from './programs/programs.component';
import { PlaylistsComponent } from './playlists/playlists.component';
import { TargetsComponent } from './targets/targets.component';
import { LibraryComponent } from './library/library.component';
import { ProgramComponent } from './programs/program/program.component';
import { PlaylistComponent } from './playlists/playlist/playlist.component';
import { AddProgramComponent } from './programs/add-program/add-program.component';
import { AddPlaylistComponent } from './playlists/add-playlist/add-playlist.component';
import { AddTargetComponent } from './targets/add-target/add-target.component';
import { TargetComponent } from './targets/target/target.component';
import { AddStaffAlertComponent } from './add-staff-alert/add-staff-alert.component';
import { DragDropModule } from 'primeng/dragdrop';
import { SettingsComponent } from './settings/settings.component';
import {
  CdkDragDrop,
  CdkDropList,
  CdkDrag,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { SkillAreasComponent } from './skill-areas/skill-areas.component';
import { AddSkillAreaComponent } from './skill-areas/add-skill-area/add-skill-area.component';
import { RemindersComponent } from './reminders/reminders.component';
import { AddReminderComponent } from './reminders/add-reminder/add-reminder.component';

const pages = [
  SupervisorMainComponent,
  ProgramsComponent,
  PlaylistsComponent,
  TargetsComponent,
  LibraryComponent,
  ProgramComponent,
  PlaylistComponent,
  AddProgramComponent,
  AddPlaylistComponent,
  AddTargetComponent,
  TargetComponent,
  AddStaffAlertComponent,
  SettingsComponent,
  SkillAreasComponent,
  AddSkillAreaComponent,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SupervisorRoutingModule,
    DragDropModule,
    InputNumberModule,
    CdkDropList,
    CdkDrag,
  ],
  declarations: [...pages, RemindersComponent, AddReminderComponent],
})
export class SupervisorModule {
  constructor(@Optional() @SkipSelf() parentModule: SupervisorModule) {
    if (parentModule) {
      throw new Error(
        'Module is already loaded. Import in your base AppModule only.'
      );
    }
  }
}
