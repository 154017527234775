import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-box-dtt',
  templateUrl: './box-dtt.component.html',
})
export class BoxDttComponent implements AfterViewInit {
  @Input() text: string|undefined = '-';
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  
  levels = [
    {
      name: 'Probe',
      color: '#DFE2AF',
    },
    {
      name: 'Acquisition',
      color: '#a87553',
    },
    {
      name: 'Mastered',
      color: '#6f9c61',
    }
  ];
  options = [
    {
      name: 'Full Physical',
      icon: 'F',
      color: '#C15757',
    },
    {
      name: 'Partial Physical',
      icon: 'P',
      color: '#C15757',
    },
    {
      name: 'Model',
      icon: 'M',
      color: '#CC9E7F',
    },
    {
      name: 'Gesture',
      icon: 'G',
      color: '#8AC379',
      isSuccess: true,
    },
    {
      name: 'Independent',
      icon: '+',
      color: '#8AC379',
      isSuccess: true,
    }
    
  ];


  extraOpened: boolean = false;
  selectedOption: any;
  extraBoxes: ElementRef[] = [];
  @Input() requiredTrials: number = 3;
  @Input() totalSuccess: number = 0;
  @Input() selectedOptions: any[] = [];
  @Input() currentLevel: any = this.levels[0];

  @ViewChild('dttBox', { static: true }) dttBox!: ElementRef;
  @ViewChild('dttExtraBox', { static: true }) dttExtraBox!: ElementRef;

  constructor(private cdRef: ChangeDetectorRef) {}
  ngAfterViewInit() {
    // Initialize the position of the dtt-extra-container
    const dttContainer = this.dttBox.nativeElement;
    const extraContainer = this.dttExtraBox.nativeElement;
    const body = document.querySelector('body') as HTMLElement;

    if (dttContainer && extraContainer) {
      // Append the extra container to the dtt container
      body.appendChild(extraContainer);
      this.updateExtraContainerPosition(dttContainer, extraContainer);

    }

    // Update position on window resize
    window.addEventListener('resize', () => {
      this.updateExtraContainerPosition(dttContainer, extraContainer);
    });
    // Update position on scroll
    window.addEventListener('scroll', () => {
      this.updateExtraContainerPosition(dttContainer, extraContainer);
    });
    const scrollableElements = document.querySelectorAll('div');
    scrollableElements.forEach(element => {
      element.addEventListener('scroll', () => {
        this.updateExtraContainerPosition(dttContainer, extraContainer);
      });
    });

    // Observe changes in the DOM that might affect positioning
    const observer = new MutationObserver(() => {
      this.updateExtraContainerPosition(dttContainer, extraContainer);
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true
    });
    this.extraBoxes = [this.dttExtraBox];
    this.cdRef.detectChanges();
  }

  onClickOutside() {
    if (this.extraOpened) {
      this.dttExtraBox.nativeElement.classList.add('hide');
      this.extraOpened = false;
    }
  }

  private updateExtraContainerPosition(dttContainer: HTMLElement, extraContainer: HTMLElement): void {
    const top = dttContainer.offsetTop;
    const rect = dttContainer.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const containerRight = rect.right;
    const spaceOnRight = windowWidth - containerRight;
    const spaceOnLeft = rect.left;
    const extraContainerWidth = 180; // Width of the extra container

    if (spaceOnRight < extraContainerWidth + 50 && spaceOnLeft >= extraContainerWidth + 50) {
      // Position to the left if there's not enough space on the right
      extraContainer.style.left = `${rect.left - extraContainerWidth - 10}px`;
    } else {
      // Default positioning to the right
      extraContainer.style.left = `${rect.left + rect.width + 10}px`;
    }

    extraContainer.style.top = `${top}px`;
  }


  showExtra(): void {
    this.extraOpened = !this.extraOpened;
    if (this.extraOpened) {
      // Hide all other extra containers
      const allExtraContainers = document.querySelectorAll('.extra-container');
      allExtraContainers.forEach((container: Element) => {
        if (container instanceof HTMLElement && container !== this.dttExtraBox.nativeElement) {
          container.classList.add('hide');
        }
      });

      // Ensure the current extra container is visible
      this.dttExtraBox.nativeElement.classList.remove('hide');

      // Update the position of the extra container
      setTimeout(() => {
        const dttContainer = this.dttBox.nativeElement;
        const extraContainer = this.dttExtraBox.nativeElement;
        this.updateExtraContainerPosition(dttContainer, extraContainer);
      });
    }
  }
  
  closeExtra() {
    this.extraOpened = false;
  }

  selectOption(option: any) {
    // Store the selected option
    this.selectedOption = option;

    // Initialize the selectedOptions array if it doesn't exist
    if (!this.selectedOptions) {
      this.selectedOptions = [];
    }

    if (option.isSuccess) {
      this.totalSuccess++;
    }

    // Add the selected option to the array
    this.selectedOptions.push(option);

    // Close the extra container after selection
    this.closeExtra();
  }

}