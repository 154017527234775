

<form [formGroup]="targetForm" (ngSubmit)="onSubmit()">

  <h2>{{ titleName }}</h2>

  <div class="control-row">
    <div class="control no-margin">
      <label for="targetName">Target Name</label>
      <input type="text" id="targetName" name="targetName" formControlName="targetName" />
    </div>
    @if (targetForm.controls.targetName.invalid && targetForm.controls.targetName.touched) {
      <span class="control-error">Target Name is required.</span>
    }
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="goalName">Goal Name</label>
      <input type="text" id="goalName" formControlName="goalName" />
      @if (targetForm.controls.goalName.invalid && targetForm.controls.goalName.touched) {
        <span class="control-error">Goal Name is required.</span>
      }
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="discriminativeStimulus">SD (Discriminative Stimulus)</label>
      <input type="text" id="discriminativeStimulus" formControlName="discriminativeStimulus" />
    </div>
    @if (targetForm.controls.discriminativeStimulus.invalid && targetForm.controls.discriminativeStimulus.touched) {
      <span class="control-error">SD is required.</span>
    }
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="desiredTrials">Desired Trials</label>
      <p-inputNumber [showButtons]="true" inputId="desiredTrials" formControlName="desiredTrials" />
      @if(targetForm.controls.desiredTrials.invalid && targetForm.controls.desiredTrials.touched) {
        <span class="control-error">Desired Trials is required and must be between 1 and 100.</span>
      }
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
        <label for="targetType">Target Type:</label>
        <p-dropdown 
          [options]="targetTypes" 
          formControlName="targetType"
          optionValue="value" 
          optionLabel="text">
        </p-dropdown>
      @if(targetForm.controls.targetType.invalid && targetForm.controls.targetType.touched) {
        <span class="control-error">Target Type is required.</span>
      }
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="selectedProgram">Program:</label>
      <p-dropdown 
        [options]="programs"
        formControlName="patientProgramID"
        optionLabel="ProgramName"
        optionValue="ID"
        [filter]="true"
        filterBy="ProgramName" 
        [showClear]="true"
        [virtualScroll]="true"
        [virtualScrollItemSize]="50"
        [loading]="loadingPrograms"
        placeholder="Select a Program">
            <ng-template pTemplate="selectedProgram" let-selectedOption>
                <div class="flex align-items-center gap-2">
                    <div>{{ selectedOption.ProgramName }}</div>
                </div>
            </ng-template>
      </p-dropdown>
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <label for="instructions">Instructions</label>
      <textarea id="instructions" rows="3" formControlName="instructions"></textarea>
    </div>
  </div>

  <div class="control-row">
    <div class="control no-margin">
      <p-checkbox 
        formControlName="isFavorite" 
        [binary]="true" 
        inputId="isFavorite" />
      <label for="isFavorite">Favorite</label>
    </div>
  </div>
    

  <!-- TODO: Advance Options -->

  <p class="horizontal-flex-list">
    <button type="button" class="switch-mode-button" (click)="onCancel()">Cancel</button>
    <button type="submit" class="side-menu-button2" >{{ actionName }}</button>
  </p>

</form>