import { effect, Injectable, signal } from '@angular/core';
import { PatientSessionModel, PatientTargetDataModel } from '../../core/api/models';
import { StaffApiService } from '../../core/api/services';

@Injectable({
    providedIn: 'root'
})
export class PatientSessionService {

    patientSession = signal<PatientSessionModel | null>(null)

    constructor(
        private staffApiService: StaffApiService) {
        effect(() => {
            const patientSession = this.patientSession();
            if (patientSession) {
                //save to local storage
                localStorage.setItem('patientSession', JSON.stringify(patientSession));
            }
        });

        //load from local storage
        const patientSession = localStorage.getItem('patientSession');
        if (patientSession) {
            this.patientSession.set(JSON.parse(patientSession));
        }

        //  //Check if there is a new version on the backend and update if needed
        //  this.staffApiService.getPatientOpenSession(this.patientSession().ID).subscribe(patientSession => {
        //     //Check if the version is different
        //     if (patientSession.LastUpdateDate > this.patientSession().LastUpdateDate) {
        //         this.patientSession.set(patientSession);
        //     }
        //  });

    }

    startSession(patientID: number): void {
        const startTime = Date.now();
        const patientSession: PatientSessionModel = {
            StartedDate: new Date(startTime).toISOString(),
            BehaviorData: [],
            TargetData: [],
            PatientID: patientID
        };
        this.patientSession.set(patientSession);
    }

    endSession(): void {
        this.patientSession.update(patientSession => {
            if (patientSession) {
                patientSession.FinishedDate = new Date().toISOString();
            }
            return Object.assign({}, patientSession);
        });
    }

    getTargetData(targetID: number): PatientTargetDataModel[] | null {
        const patientSession = this.patientSession();
        if (!patientSession || !patientSession.TargetData) {
            return null;
        }

        const targetData = patientSession.TargetData?.filter(t => t.PatientTargetID === targetID);
        return targetData || null;
    }

    addTargetData(targetData: PatientTargetDataModel): void {
        const patientSession = this.patientSession();
        if (!patientSession) {
            return;
        }

        //use update to add the target data
        this.patientSession.update(patientSession => {
            if (!patientSession) {
                return patientSession;
            }
            if (!patientSession.TargetData) {
                patientSession.TargetData = [];
            }
            if (!targetData.PatientSessionID) {
                targetData.PatientSessionID = patientSession.ID;
            }
            if (!targetData.RecordedDate) {
                targetData.RecordedDate = new Date().toISOString();
            }
            patientSession.TargetData.push(targetData);
            return Object.assign({}, patientSession);
        });
    }

    saveTargetData(targetData: PatientTargetDataModel): void {
        //use update to save the target data    
        this.patientSession.update(patientSession => {
            if (!patientSession) {
                return patientSession;
            }
            if (!patientSession.TargetData) {
                patientSession.TargetData = [];
            }
            const targetDataIndex = patientSession.TargetData?.findIndex(t => t.PatientTargetID === targetData.PatientTargetID && t.RecordedDate === targetData.RecordedDate);
            if (targetDataIndex !== undefined && targetDataIndex !== -1) {
                patientSession.TargetData[targetDataIndex] = targetData;
            }
            return Object.assign({}, patientSession);
        });
    }


}