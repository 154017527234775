import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-box-fluencyrate',
  templateUrl: './box-fluencyrate.component.html',
})
export class BoxFluencyrateComponent implements AfterViewInit, OnInit {
  @Input() text: string|undefined = '-';
  @Input() expectedAmount: number = 0;
  @Input() isTopBox: boolean = true;
  @Input() isBottomBox: boolean = false;
  @Input() timerInitialValue: number = 60;
  private longPressTimeout: any;
  isStarted: boolean = false;
  timerValue: number = 0;
  private startTime: number = 0;
  fluencies: any[] = [
  ];
  extraOpened: boolean = false;
  totalCorrect: number = 0;
  totalIncorrect: number = 0;
  extraListOpened: boolean = false;
  @ViewChild('fluencyrateBox', { static: true }) fluencyrateBox!: ElementRef;
  @ViewChild('fluencyrateExtraBox', { static: true }) fluencyrateExtraBox!: ElementRef;
  @ViewChild('fluencyrateExtraListBox', { static: true }) fluencyrateExtraListBox!: ElementRef;
  extraBoxes: ElementRef[] = [];

  constructor(private cdRef: ChangeDetectorRef) {}
  ngOnInit() {
    this.timerValue = this.timerInitialValue;
  }

  ngAfterViewInit() {
    // Initialize the position of the duration-extra-container
    const durationContainer = this.fluencyrateBox.nativeElement;
    const extraContainer = this.fluencyrateExtraBox.nativeElement;
    const extraListContainer = this.fluencyrateExtraListBox.nativeElement;
    const body = document.querySelector('body') as HTMLElement;

    if (durationContainer && extraContainer) {
      // Append the extra container to the duration container
      body.appendChild(extraContainer);
      this.updateExtraContainerPosition(durationContainer, extraContainer);
      body.appendChild(extraListContainer);
      this.updateExtraContainerPosition(durationContainer, extraListContainer);

    }

    // Update position on window resize
    window.addEventListener('resize', () => {
      this.updateExtraContainerPosition(durationContainer, extraContainer);
      this.updateExtraContainerPosition(durationContainer, extraListContainer);
    });
    // Update position on scroll
    window.addEventListener('scroll', () => {
      this.updateExtraContainerPosition(durationContainer, extraContainer);
      this.updateExtraContainerPosition(durationContainer, extraListContainer);
    });
    const scrollableElements = document.querySelectorAll('div');
    scrollableElements.forEach(element => {
      element.addEventListener('scroll', () => {
        this.updateExtraContainerPosition(durationContainer, extraContainer);
        this.updateExtraContainerPosition(durationContainer, extraListContainer);
      });
    });

    // Observe changes in the DOM that might affect positioning
    const observer = new MutationObserver(() => {
      this.updateExtraContainerPosition(durationContainer, extraContainer);
      this.updateExtraContainerPosition(durationContainer, extraListContainer);
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      characterData: true
    });
    this.extraBoxes = [this.fluencyrateExtraBox,this.fluencyrateExtraListBox];
    this.cdRef.detectChanges();
  }

  onClickOutside() {
    if (this.extraOpened || this.extraListOpened) {
      this.extraOpened = false;
      this.extraListOpened = false;
    }
  }

  private updateExtraContainerPosition(durationContainer: HTMLElement, extraContainer: HTMLElement): void {
    const top = durationContainer.offsetTop;
    const rect = durationContainer.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const containerRight = rect.right;
    const spaceOnRight = windowWidth - containerRight;
    const spaceOnLeft = rect.left;
    const extraContainerWidth = 180; // Width of the extra container

    if (spaceOnRight < extraContainerWidth + 50 && spaceOnLeft >= extraContainerWidth + 50) {
      // Position to the left if there's not enough space on the right
      extraContainer.style.left = `${rect.left - extraContainerWidth - 10}px`;
    } else {
      // Default positioning to the right
      extraContainer.style.left = `${rect.left + rect.width + 10}px`;
    }

    extraContainer.style.top = `${top}px`;
  }

  startLongPress(): void {
    this.longPressTimeout = setTimeout(() => {
      this.doLongPress();
    }, 400);
  }

  doLongPress(): void {
    this.showExtraList();
  }

  showExtra(): void {
    this.extraOpened = !this.extraOpened;
    if (this.extraOpened) {
      // Hide all other extra containers
      const allExtraContainers = document.querySelectorAll('.extra-container');
      allExtraContainers.forEach((container: Element) => {
        if (container instanceof HTMLElement && container !== this.fluencyrateExtraBox.nativeElement) {
          container.classList.add('hide');
        }
      });

      // Ensure the current extra container is visible
      this.fluencyrateExtraBox.nativeElement.classList.remove('hide');

      // Update the position of the extra container
      setTimeout(() => {
        const durationContainer = this.fluencyrateBox.nativeElement;
        const extraContainer = this.fluencyrateExtraBox.nativeElement;
        this.updateExtraContainerPosition(durationContainer, extraContainer);
      });
    }
  }

  endLongPress(): void {
    clearTimeout(this.longPressTimeout);
  }

  showExtraList(): void {
    this.extraListOpened = !this.extraListOpened;
    if (this.extraListOpened) {
      // Hide all other extra list containers
      const allExtraListContainers = document.querySelectorAll('.extra-container');
      allExtraListContainers.forEach((container: Element) => {
        if (container instanceof HTMLElement && container !== this.fluencyrateExtraListBox.nativeElement) {
          container.classList.add('hide');
        }
      });

      // Ensure the current extra list container is visible
      this.fluencyrateExtraListBox.nativeElement.classList.remove('hide');

      // Update the position of the extra list container
      setTimeout(() => {
        const durationContainer = this.fluencyrateBox.nativeElement;
        const extraListContainer = this.fluencyrateExtraListBox.nativeElement;
        this.updateExtraContainerPosition(durationContainer, extraListContainer);
      });
    }
  }

  startTimer() {
    if (this.isStarted) {
      this.showExtra();
    } else {
      this.isStarted = true;
      this.startTime = Date.now();
      this.totalCorrect = 0;
      this.totalIncorrect = 0;
      this.updateTimer();
      this.showExtra();
    }
  }

  private updateTimer(): void {
    if (this.isStarted) {
      const currentTime = Date.now();
      this.timerValue = this.timerInitialValue - Math.floor((currentTime - this.startTime) / 1000);
      if (this.timerValue <= 0) {
        this.stopTimer();
      } else {
        setTimeout(() => this.updateTimer(), 1000);
      }
    }
  }



  stopTimer() {
    if (this.totalCorrect > 0 || this.totalIncorrect > 0) {
      const currentDate = new Date();
      const formattedDate = this.formatDate(currentDate);
      const total = this.totalCorrect + this.totalIncorrect;
      const rate = 100 * this.totalCorrect / total;
      this.fluencies.push({ totalCorrect: this.totalCorrect, totalIncorrect: this.totalIncorrect, date: formattedDate,rate:rate });
    }
    this.timerValue = this.timerInitialValue;
    this.isStarted = false;
    this.closeExtra();
  }

  timerString() {
    const minutes = Math.floor(this.timerValue / 60);
    const seconds = this.timerValue % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  closeExtra() {
    this.extraOpened = false;
  }

  private formatDate(date: Date): string {
    const today = new Date();
    if (date.toDateString() === today.toDateString()) {
      return 'at ' + date.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      }).toLowerCase();
    } else {
      return 'on ' + date.toLocaleString('en-US', {
        day: 'numeric',
        month: 'short',
        year: '2-digit'
      });
    }
  }

  lastFluency() {
    if (this.fluencies.length > 0) {
      const lastFluency = this.fluencies[this.fluencies.length - 1];
      return `${lastFluency.totalCorrect} / ${lastFluency.rate.toFixed(0)}%`;
    } else {
      return '';
    }
  }

  incrementCorrect() {
    this.totalCorrect++;
  }

  incrementIncorrect() {
    this.totalIncorrect++;
  }

  closeExtraList() {
    this.extraListOpened = false;
  }
}